.fixed-bar {
    bottom: 6vh;
    right: 3%;
    z-index: 5;
    opacity: 1;
    display: flex;
    position: fixed;
    transition: .4s ease all;
    &.opacity {
        opacity: 0;
    }
    .btn {
        padding: 0;
        position: relative;
        z-index: 98;
        width: 5.5rem;
        height: 5.5rem;
        font-size: 1.5rem;
        color: transparent;
        background: #606060;
        border: 2px solid #fff;
        margin-left: 1rem;
        transition: .2s ease all;
        &:hover {
            background: #b01b40;
            border: 2px solid #b01b40;
            span {
                color: #fff !important;
            }
        }
        &.disabled {
            opacity: .3 !important;
            cursor: not-allowed !important;
            pointer-events: unset !important;
        }
    }
    &.opacity-total {
        opacity: 0;
    }
    .contact-btn {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #606060;
        color: #fff;
        font-size: 2.5rem;
        position: relative;
        text-decoration: none;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: #b01b40;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0);
            will-change: transform;
            transition: 0.4s;
            border-radius: 50%;
        }
        &:hover:after {
            transform: scale(1);
            opacity: 1;
        }
        &:before {
            z-index: 9;
            transition: 0.3s;
            transition-delay: 0.1s;
        }
    }
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.justify-content-center {
    justify-content: center !important;
}

.align-items {
    align-items: center !important;
}

#unidades,
.modulos,
.propiedad {
    /*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
     :root {
        --bs-blue: #0d6efd;
        --bs-indigo: #6610f2;
        --bs-purple: #6f42c1;
        --bs-pink: #d63384;
        --bs-red: #dc3545;
        --bs-orange: #fd7e14;
        --bs-yellow: #ffc107;
        --bs-green: #198754;
        --bs-teal: #20c997;
        --bs-cyan: #0dcaf0;
        --bs-white: #fff;
        --bs-gray: #6c757d;
        --bs-gray-dark: #343a40;
        --bs-primary: #0d6efd;
        --bs-secondary: #6c757d;
        --bs-success: #198754;
        --bs-info: #0dcaf0;
        --bs-warning: #ffc107;
        --bs-danger: #dc3545;
        --bs-light: #f8f9fa;
        --bs-dark: #212529;
        --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
    }
    *,
     ::after,
     ::before {
        box-sizing: border-box
    }
    @media (prefers-reduced-motion:no-preference) {
         :root {
            scroll-behavior: smooth
        }
    }
    body {
        margin: 0;
        font-family: var(--bs-font-sans-serif);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent
    }
    hr {
        margin: 1rem 0;
        color: inherit;
        background-color: currentColor;
        border: 0;
        opacity: .25
    }
    hr:not([size]) {
        height: 1px;
    }
    abbr[data-bs-original-title],
    abbr[title] {
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        cursor: help;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none
    }
    address {
        margin-bottom: 1rem;
        font-style: normal;
        line-height: inherit
    }
    ol,
    ul {
        padding-left: 2rem
    }
    dl,
    ol,
    ul {
        margin-top: 0;
        margin-bottom: 1rem
    }
    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin-bottom: 0
    }
    dt {
        font-weight: 700
    }
    dd {
        margin-bottom: .5rem;
        margin-left: 0
    }
    blockquote {
        margin: 0 0 1rem
    }
    b,
    strong {
        font-weight: bolder
    }
    .small,
    small {
        font-size: .875em
    }
    .mark,
    mark {
        padding: .2em;
        background-color: #fcf8e3
    }
    sub,
    sup {
        position: relative;
        font-size: .75em;
        line-height: 0;
        vertical-align: baseline
    }
    sub {
        bottom: -.25em
    }
    sup {
        top: -.5em
    }
    a {
        color: #0d6efd;
        text-decoration: underline
    }
    a:hover {
        color: #0a58ca
    }
    a:not([href]):not([class]),
    a:not([href]):not([class]):hover {
        color: inherit;
        text-decoration: none
    }
    code,
    kbd,
    pre,
    samp {
        font-family: var(--bs-font-monospace);
        font-size: 1em;
        direction: ltr;
        unicode-bidi: bidi-override
    }
    pre {
        display: block;
        margin-top: 0;
        margin-bottom: 1rem;
        overflow: auto;
        font-size: .875em
    }
    pre code {
        font-size: inherit;
        color: inherit;
        word-break: normal
    }
    code {
        font-size: .875em;
        color: #d63384;
        word-wrap: break-word
    }
    a>code {
        color: inherit
    }
    kbd {
        padding: .2rem .4rem;
        font-size: .875em;
        color: #fff;
        background-color: #212529;
        border-radius: .2rem
    }
    kbd kbd {
        padding: 0;
        font-size: 1em;
        font-weight: 700
    }
    figure {
        margin: 0 0 1rem
    }
    img,
    svg {
        vertical-align: middle
    }
    table {
        caption-side: bottom;
        border-collapse: collapse
    }
    caption {
        padding-top: .5rem;
        padding-bottom: .5rem;
        color: #6c757d;
        text-align: left
    }
    th {
        text-align: inherit;
        text-align: -webkit-match-parent
    }
    tbody,
    td,
    tfoot,
    th,
    thead,
    tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0
    }
    label {
        display: inline-block
    }
    button {
        border-radius: 0
    }
    button:focus:not(:focus-visible) {
        outline: 0
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit
    }
    button,
    select {
        text-transform: none
    }
    [role=button] {
        cursor: pointer
    }
    select {
        word-wrap: normal
    }
    select:disabled {
        opacity: 1
    }
    [list]::-webkit-calendar-picker-indicator {
        display: none
    }
    [type=button],
    [type=reset],
    [type=submit],
    button {
        -webkit-appearance: button
    }
    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer
    }
     ::-moz-focus-inner {
        padding: 0;
        border-style: none
    }
    textarea {
        resize: vertical
    }
    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0
    }
    legend {
        float: left;
        width: 100%;
        padding: 0;
        margin-bottom: .5rem;
        font-size: calc(1.275rem + .3vw);
        line-height: inherit
    }
    @media (min-width:1200px) {
        legend {
            font-size: 1.5rem
        }
    }
    legend+* {
        clear: left
    }
     ::-webkit-datetime-edit-day-field,
     ::-webkit-datetime-edit-fields-wrapper,
     ::-webkit-datetime-edit-hour-field,
     ::-webkit-datetime-edit-minute,
     ::-webkit-datetime-edit-month-field,
     ::-webkit-datetime-edit-text,
     ::-webkit-datetime-edit-year-field {
        padding: 0
    }
     ::-webkit-inner-spin-button {
        height: auto
    }
    [type=search] {
        outline-offset: -2px;
        -webkit-appearance: textfield
    }
     ::-webkit-search-decoration {
        -webkit-appearance: none
    }
     ::-webkit-color-swatch-wrapper {
        padding: 0
    }
     ::file-selector-button {
        font: inherit
    }
     ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button
    }
    output {
        display: inline-block
    }
    iframe {
        border: 0
    }
    summary {
        display: list-item;
        cursor: pointer
    }
    progress {
        vertical-align: baseline
    }
    [hidden] {
        display: none!important
    }
    .lead {
        font-size: 1.25rem;
        font-weight: 300
    }
    .display-1 {
        font-size: calc(1.625rem + 4.5vw);
        font-weight: 300;
        line-height: 1.2
    }
    @media (min-width:1200px) {
        .display-1 {
            font-size: 5rem
        }
    }
    .display-2 {
        font-size: calc(1.575rem + 3.9vw);
        font-weight: 300;
        line-height: 1.2
    }
    @media (min-width:1200px) {
        .display-2 {
            font-size: 4.5rem
        }
    }
    .display-3 {
        font-size: calc(1.525rem + 3.3vw);
        font-weight: 300;
        line-height: 1.2
    }
    @media (min-width:1200px) {
        .display-3 {
            font-size: 4rem
        }
    }
    .display-4 {
        font-size: calc(1.475rem + 2.7vw);
        font-weight: 300;
        line-height: 1.2
    }
    @media (min-width:1200px) {
        .display-4 {
            font-size: 3.5rem
        }
    }
    .display-5 {
        font-size: calc(1.425rem + 2.1vw);
        font-weight: 300;
        line-height: 1.2
    }
    @media (min-width:1200px) {
        .display-5 {
            font-size: 3rem
        }
    }
    .display-6 {
        font-size: calc(1.375rem + 1.5vw);
        font-weight: 300;
        line-height: 1.2
    }
    @media (min-width:1200px) {
        .display-6 {
            font-size: 2.5rem
        }
    }
    .list-unstyled {
        padding-left: 0;
        list-style: none
    }
    .list-inline {
        padding-left: 0;
        list-style: none
    }
    .list-inline-item {
        display: inline-block
    }
    .list-inline-item:not(:last-child) {
        margin-right: .5rem
    }
    .initialism {
        font-size: .875em;
        text-transform: uppercase
    }
    .blockquote {
        margin-bottom: 1rem;
        font-size: 1.25rem
    }
    .blockquote>:last-child {
        margin-bottom: 0
    }
    .blockquote-footer {
        margin-top: -1rem;
        margin-bottom: 1rem;
        font-size: .875em;
        color: #6c757d
    }
    .blockquote-footer::before {
        content: "— "
    }
    .img-fluid {
        max-width: 100%;
        height: auto
    }
    .img-thumbnail {
        padding: .25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: .25rem;
        max-width: 100%;
        height: auto
    }
    .figure {
        display: inline-block
    }
    .figure-img {
        margin-bottom: .5rem;
        line-height: 1
    }
    .figure-caption {
        font-size: .875em;
        color: #6c757d
    }
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        width: 100%;
        padding-right: var(--bs-gutter-x, .75rem);
        padding-left: var(--bs-gutter-x, .75rem);
        margin-right: auto;
        margin-left: auto
    }
    @media (min-width:576px) {
        .container,
        .container-sm {
            max-width: 540px
        }
    }
    @media (min-width:768px) {
        .container,
        .container-md,
        .container-sm {
            max-width: 720px
        }
    }
    @media (min-width:992px) {
        .container,
        .container-lg,
        .container-md,
        .container-sm {
            max-width: 960px
        }
    }
    @media (min-width:1200px) {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl {
            max-width: 1140px
        }
    }
    @media (min-width:1400px) {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl,
        .container-xxl {
            max-width: 1320px
        }
    }
    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--bs-gutter-y) * -1);
        margin-right: calc(var(--bs-gutter-x) * -.5);
        margin-left: calc(var(--bs-gutter-x) * -.5)
    }
    .row>* {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y)
    }
    .col {
        flex: 1 0 0%
    }
    .row-cols-auto>* {
        flex: 0 0 auto;
        width: auto
    }
    .row-cols-1>* {
        flex: 0 0 auto;
        width: 100%
    }
    .row-cols-2>* {
        flex: 0 0 auto;
        width: 50%
    }
    .row-cols-3>* {
        flex: 0 0 auto;
        width: 33.3333333333%
    }
    .row-cols-4>* {
        flex: 0 0 auto;
        width: 25%
    }
    .row-cols-5>* {
        flex: 0 0 auto;
        width: 20%
    }
    .row-cols-6>* {
        flex: 0 0 auto;
        width: 16.6666666667%
    }
    @media (min-width:576px) {
        .col-sm {
            flex: 1 0 0%
        }
        .row-cols-sm-auto>* {
            flex: 0 0 auto;
            width: auto
        }
        .row-cols-sm-1>* {
            flex: 0 0 auto;
            width: 100%
        }
        .row-cols-sm-2>* {
            flex: 0 0 auto;
            width: 50%
        }
        .row-cols-sm-3>* {
            flex: 0 0 auto;
            width: 33.3333333333%
        }
        .row-cols-sm-4>* {
            flex: 0 0 auto;
            width: 25%
        }
        .row-cols-sm-5>* {
            flex: 0 0 auto;
            width: 20%
        }
        .row-cols-sm-6>* {
            flex: 0 0 auto;
            width: 16.6666666667%
        }
    }
    @media (min-width:768px) {
        .col-md {
            flex: 1 0 0%
        }
        .row-cols-md-auto>* {
            flex: 0 0 auto;
            width: auto
        }
        .row-cols-md-1>* {
            flex: 0 0 auto;
            width: 100%
        }
        .row-cols-md-2>* {
            flex: 0 0 auto;
            width: 50%
        }
        .row-cols-md-3>* {
            flex: 0 0 auto;
            width: 33.3333333333%
        }
        .row-cols-md-4>* {
            flex: 0 0 auto;
            width: 25%
        }
        .row-cols-md-5>* {
            flex: 0 0 auto;
            width: 20%
        }
        .row-cols-md-6>* {
            flex: 0 0 auto;
            width: 16.6666666667%
        }
    }
    @media (min-width:992px) {
        .col-lg {
            flex: 1 0 0%
        }
        .row-cols-lg-auto>* {
            flex: 0 0 auto;
            width: auto
        }
        .row-cols-lg-1>* {
            flex: 0 0 auto;
            width: 100%
        }
        .row-cols-lg-2>* {
            flex: 0 0 auto;
            width: 50%
        }
        .row-cols-lg-3>* {
            flex: 0 0 auto;
            width: 33.3333333333%
        }
        .row-cols-lg-4>* {
            flex: 0 0 auto;
            width: 25%
        }
        .row-cols-lg-5>* {
            flex: 0 0 auto;
            width: 20%
        }
        .row-cols-lg-6>* {
            flex: 0 0 auto;
            width: 16.6666666667%
        }
    }
    @media (min-width:1200px) {
        .col-xl {
            flex: 1 0 0%
        }
        .row-cols-xl-auto>* {
            flex: 0 0 auto;
            width: auto
        }
        .row-cols-xl-1>* {
            flex: 0 0 auto;
            width: 100%
        }
        .row-cols-xl-2>* {
            flex: 0 0 auto;
            width: 50%
        }
        .row-cols-xl-3>* {
            flex: 0 0 auto;
            width: 33.3333333333%
        }
        .row-cols-xl-4>* {
            flex: 0 0 auto;
            width: 25%
        }
        .row-cols-xl-5>* {
            flex: 0 0 auto;
            width: 20%
        }
        .row-cols-xl-6>* {
            flex: 0 0 auto;
            width: 16.6666666667%
        }
    }
    @media (min-width:1400px) {
        .col-xxl {
            flex: 1 0 0%
        }
        .row-cols-xxl-auto>* {
            flex: 0 0 auto;
            width: auto
        }
        .row-cols-xxl-1>* {
            flex: 0 0 auto;
            width: 100%
        }
        .row-cols-xxl-2>* {
            flex: 0 0 auto;
            width: 50%
        }
        .row-cols-xxl-3>* {
            flex: 0 0 auto;
            width: 33.3333333333%
        }
        .row-cols-xxl-4>* {
            flex: 0 0 auto;
            width: 25%
        }
        .row-cols-xxl-5>* {
            flex: 0 0 auto;
            width: 20%
        }
        .row-cols-xxl-6>* {
            flex: 0 0 auto;
            width: 16.6666666667%
        }
    }
    .col-auto {
        flex: 0 0 auto;
        width: auto
    }
    .col-1 {
        flex: 0 0 auto;
        width: 8.33333333%
    }
    .col-2 {
        flex: 0 0 auto;
        width: 16.66666667%
    }
    .col-3 {
        flex: 0 0 auto;
        width: 25%
    }
    .col-4 {
        flex: 0 0 auto;
        width: 33.33333333%
    }
    .col-5 {
        flex: 0 0 auto;
        width: 41.66666667%
    }
    .col-6 {
        flex: 0 0 auto;
        width: 50%
    }
    .col-7 {
        flex: 0 0 auto;
        width: 58.33333333%
    }
    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%
    }
    .col-9 {
        flex: 0 0 auto;
        width: 75%
    }
    .col-10 {
        flex: 0 0 auto;
        width: 83.33333333%
    }
    .col-11 {
        flex: 0 0 auto;
        width: 91.66666667%
    }
    .col-12 {
        flex: 0 0 auto;
        width: 100%
    }
    .offset-1 {
        margin-left: 8.33333333%
    }
    .offset-2 {
        margin-left: 16.66666667%
    }
    .offset-3 {
        margin-left: 25%
    }
    .offset-4 {
        margin-left: 33.33333333%
    }
    .offset-5 {
        margin-left: 41.66666667%
    }
    .offset-6 {
        margin-left: 50%
    }
    .offset-7 {
        margin-left: 58.33333333%
    }
    .offset-8 {
        margin-left: 66.66666667%
    }
    .offset-9 {
        margin-left: 75%
    }
    .offset-10 {
        margin-left: 83.33333333%
    }
    .offset-11 {
        margin-left: 91.66666667%
    }
    .g-0,
    .gx-0 {
        --bs-gutter-x: 0
    }
    .g-0,
    .gy-0 {
        --bs-gutter-y: 0
    }
    .g-1,
    .gx-1 {
        --bs-gutter-x: 0.25rem
    }
    .g-1,
    .gy-1 {
        --bs-gutter-y: 0.25rem
    }
    .g-2,
    .gx-2 {
        --bs-gutter-x: 0.5rem
    }
    .g-2,
    .gy-2 {
        --bs-gutter-y: 0.5rem
    }
    .g-3,
    .gx-3 {
        --bs-gutter-x: 1rem
    }
    .g-3,
    .gy-3 {
        --bs-gutter-y: 1rem
    }
    .g-4,
    .gx-4 {
        --bs-gutter-x: 1.5rem
    }
    .g-4,
    .gy-4 {
        --bs-gutter-y: 1.5rem
    }
    .g-5,
    .gx-5 {
        --bs-gutter-x: 3rem
    }
    .g-5,
    .gy-5 {
        --bs-gutter-y: 3rem
    }
    @media (min-width:576px) {
        .col-sm-auto {
            flex: 0 0 auto;
            width: auto
        }
        .col-sm-1 {
            flex: 0 0 auto;
            width: 8.33333333%
        }
        .col-sm-2 {
            flex: 0 0 auto;
            width: 16.66666667%
        }
        .col-sm-3 {
            flex: 0 0 auto;
            width: 25%
        }
        .col-sm-4 {
            flex: 0 0 auto;
            width: 33.33333333%
        }
        .col-sm-5 {
            flex: 0 0 auto;
            width: 41.66666667%
        }
        .col-sm-6 {
            flex: 0 0 auto;
            width: 50%
        }
        .col-sm-7 {
            flex: 0 0 auto;
            width: 58.33333333%
        }
        .col-sm-8 {
            flex: 0 0 auto;
            width: 66.66666667%
        }
        .col-sm-9 {
            flex: 0 0 auto;
            width: 75%
        }
        .col-sm-10 {
            flex: 0 0 auto;
            width: 83.33333333%
        }
        .col-sm-11 {
            flex: 0 0 auto;
            width: 91.66666667%
        }
        .col-sm-12 {
            flex: 0 0 auto;
            width: 100%
        }
        .offset-sm-0 {
            margin-left: 0
        }
        .offset-sm-1 {
            margin-left: 8.33333333%
        }
        .offset-sm-2 {
            margin-left: 16.66666667%
        }
        .offset-sm-3 {
            margin-left: 25%
        }
        .offset-sm-4 {
            margin-left: 33.33333333%
        }
        .offset-sm-5 {
            margin-left: 41.66666667%
        }
        .offset-sm-6 {
            margin-left: 50%
        }
        .offset-sm-7 {
            margin-left: 58.33333333%
        }
        .offset-sm-8 {
            margin-left: 66.66666667%
        }
        .offset-sm-9 {
            margin-left: 75%
        }
        .offset-sm-10 {
            margin-left: 83.33333333%
        }
        .offset-sm-11 {
            margin-left: 91.66666667%
        }
        .g-sm-0,
        .gx-sm-0 {
            --bs-gutter-x: 0
        }
        .g-sm-0,
        .gy-sm-0 {
            --bs-gutter-y: 0
        }
        .g-sm-1,
        .gx-sm-1 {
            --bs-gutter-x: 0.25rem
        }
        .g-sm-1,
        .gy-sm-1 {
            --bs-gutter-y: 0.25rem
        }
        .g-sm-2,
        .gx-sm-2 {
            --bs-gutter-x: 0.5rem
        }
        .g-sm-2,
        .gy-sm-2 {
            --bs-gutter-y: 0.5rem
        }
        .g-sm-3,
        .gx-sm-3 {
            --bs-gutter-x: 1rem
        }
        .g-sm-3,
        .gy-sm-3 {
            --bs-gutter-y: 1rem
        }
        .g-sm-4,
        .gx-sm-4 {
            --bs-gutter-x: 1.5rem
        }
        .g-sm-4,
        .gy-sm-4 {
            --bs-gutter-y: 1.5rem
        }
        .g-sm-5,
        .gx-sm-5 {
            --bs-gutter-x: 3rem
        }
        .g-sm-5,
        .gy-sm-5 {
            --bs-gutter-y: 3rem
        }
    }
    @media (min-width:768px) {
        .col-md-auto {
            flex: 0 0 auto;
            width: auto
        }
        .col-md-1 {
            flex: 0 0 auto;
            width: 8.33333333%
        }
        .col-md-2 {
            flex: 0 0 auto;
            width: 16.66666667%
        }
        .col-md-3 {
            flex: 0 0 auto;
            width: 25%
        }
        .col-md-4 {
            flex: 0 0 auto;
            width: 33.33333333%
        }
        .col-md-5 {
            flex: 0 0 auto;
            width: 41.66666667%
        }
        .col-md-6 {
            flex: 0 0 auto;
            width: 50%
        }
        .col-md-7 {
            flex: 0 0 auto;
            width: 58.33333333%
        }
        .col-md-8 {
            flex: 0 0 auto;
            width: 66.66666667%
        }
        .col-md-9 {
            flex: 0 0 auto;
            width: 75%
        }
        .col-md-10 {
            flex: 0 0 auto;
            width: 83.33333333%
        }
        .col-md-11 {
            flex: 0 0 auto;
            width: 91.66666667%
        }
        .col-md-12 {
            flex: 0 0 auto;
            width: 100%
        }
        .offset-md-0 {
            margin-left: 0
        }
        .offset-md-1 {
            margin-left: 8.33333333%
        }
        .offset-md-2 {
            margin-left: 16.66666667%
        }
        .offset-md-3 {
            margin-left: 25%
        }
        .offset-md-4 {
            margin-left: 33.33333333%
        }
        .offset-md-5 {
            margin-left: 41.66666667%
        }
        .offset-md-6 {
            margin-left: 50%
        }
        .offset-md-7 {
            margin-left: 58.33333333%
        }
        .offset-md-8 {
            margin-left: 66.66666667%
        }
        .offset-md-9 {
            margin-left: 75%
        }
        .offset-md-10 {
            margin-left: 83.33333333%
        }
        .offset-md-11 {
            margin-left: 91.66666667%
        }
        .g-md-0,
        .gx-md-0 {
            --bs-gutter-x: 0
        }
        .g-md-0,
        .gy-md-0 {
            --bs-gutter-y: 0
        }
        .g-md-1,
        .gx-md-1 {
            --bs-gutter-x: 0.25rem
        }
        .g-md-1,
        .gy-md-1 {
            --bs-gutter-y: 0.25rem
        }
        .g-md-2,
        .gx-md-2 {
            --bs-gutter-x: 0.5rem
        }
        .g-md-2,
        .gy-md-2 {
            --bs-gutter-y: 0.5rem
        }
        .g-md-3,
        .gx-md-3 {
            --bs-gutter-x: 1rem
        }
        .g-md-3,
        .gy-md-3 {
            --bs-gutter-y: 1rem
        }
        .g-md-4,
        .gx-md-4 {
            --bs-gutter-x: 1.5rem
        }
        .g-md-4,
        .gy-md-4 {
            --bs-gutter-y: 1.5rem
        }
        .g-md-5,
        .gx-md-5 {
            --bs-gutter-x: 3rem
        }
        .g-md-5,
        .gy-md-5 {
            --bs-gutter-y: 3rem
        }
    }
    @media (min-width:992px) {
        .col-lg-auto {
            flex: 0 0 auto;
            width: auto
        }
        .col-lg-1 {
            flex: 0 0 auto;
            width: 8.33333333%
        }
        .col-lg-2 {
            flex: 0 0 auto;
            width: 16.66666667%
        }
        .col-lg-3 {
            flex: 0 0 auto;
            width: 25%
        }
        .col-lg-4 {
            flex: 0 0 auto;
            width: 33.33333333%
        }
        .col-lg-5 {
            flex: 0 0 auto;
            width: 41.66666667%
        }
        .col-lg-6 {
            flex: 0 0 auto;
            width: 50%
        }
        .col-lg-7 {
            flex: 0 0 auto;
            width: 58.33333333%
        }
        .col-lg-8 {
            flex: 0 0 auto;
            width: 66.66666667%
        }
        .col-lg-9 {
            flex: 0 0 auto;
            width: 75%
        }
        .col-lg-10 {
            flex: 0 0 auto;
            width: 83.33333333%
        }
        .col-lg-11 {
            flex: 0 0 auto;
            width: 91.66666667%
        }
        .col-lg-12 {
            flex: 0 0 auto;
            width: 100%
        }
        .offset-lg-0 {
            margin-left: 0
        }
        .offset-lg-1 {
            margin-left: 8.33333333%
        }
        .offset-lg-2 {
            margin-left: 16.66666667%
        }
        .offset-lg-3 {
            margin-left: 25%
        }
        .offset-lg-4 {
            margin-left: 33.33333333%
        }
        .offset-lg-5 {
            margin-left: 41.66666667%
        }
        .offset-lg-6 {
            margin-left: 50%
        }
        .offset-lg-7 {
            margin-left: 58.33333333%
        }
        .offset-lg-8 {
            margin-left: 66.66666667%
        }
        .offset-lg-9 {
            margin-left: 75%
        }
        .offset-lg-10 {
            margin-left: 83.33333333%
        }
        .offset-lg-11 {
            margin-left: 91.66666667%
        }
        .g-lg-0,
        .gx-lg-0 {
            --bs-gutter-x: 0
        }
        .g-lg-0,
        .gy-lg-0 {
            --bs-gutter-y: 0
        }
        .g-lg-1,
        .gx-lg-1 {
            --bs-gutter-x: 0.25rem
        }
        .g-lg-1,
        .gy-lg-1 {
            --bs-gutter-y: 0.25rem
        }
        .g-lg-2,
        .gx-lg-2 {
            --bs-gutter-x: 0.5rem
        }
        .g-lg-2,
        .gy-lg-2 {
            --bs-gutter-y: 0.5rem
        }
        .g-lg-3,
        .gx-lg-3 {
            --bs-gutter-x: 1rem
        }
        .g-lg-3,
        .gy-lg-3 {
            --bs-gutter-y: 1rem
        }
        .g-lg-4,
        .gx-lg-4 {
            --bs-gutter-x: 1.5rem
        }
        .g-lg-4,
        .gy-lg-4 {
            --bs-gutter-y: 1.5rem
        }
        .g-lg-5,
        .gx-lg-5 {
            --bs-gutter-x: 3rem
        }
        .g-lg-5,
        .gy-lg-5 {
            --bs-gutter-y: 3rem
        }
    }
    @media (min-width:1200px) {
        .col-xl-auto {
            flex: 0 0 auto;
            width: auto
        }
        .col-xl-1 {
            flex: 0 0 auto;
            width: 8.33333333%
        }
        .col-xl-2 {
            flex: 0 0 auto;
            width: 16.66666667%
        }
        .col-xl-3 {
            flex: 0 0 auto;
            width: 25%
        }
        .col-xl-4 {
            flex: 0 0 auto;
            width: 33.33333333%
        }
        .col-xl-5 {
            flex: 0 0 auto;
            width: 41.66666667%
        }
        .col-xl-6 {
            flex: 0 0 auto;
            width: 50%
        }
        .col-xl-7 {
            flex: 0 0 auto;
            width: 58.33333333%
        }
        .col-xl-8 {
            flex: 0 0 auto;
            width: 66.66666667%
        }
        .col-xl-9 {
            flex: 0 0 auto;
            width: 75%
        }
        .col-xl-10 {
            flex: 0 0 auto;
            width: 83.33333333%
        }
        .col-xl-11 {
            flex: 0 0 auto;
            width: 91.66666667%
        }
        .col-xl-12 {
            flex: 0 0 auto;
            width: 100%
        }
        .offset-xl-0 {
            margin-left: 0
        }
        .offset-xl-1 {
            margin-left: 8.33333333%
        }
        .offset-xl-2 {
            margin-left: 16.66666667%
        }
        .offset-xl-3 {
            margin-left: 25%
        }
        .offset-xl-4 {
            margin-left: 33.33333333%
        }
        .offset-xl-5 {
            margin-left: 41.66666667%
        }
        .offset-xl-6 {
            margin-left: 50%
        }
        .offset-xl-7 {
            margin-left: 58.33333333%
        }
        .offset-xl-8 {
            margin-left: 66.66666667%
        }
        .offset-xl-9 {
            margin-left: 75%
        }
        .offset-xl-10 {
            margin-left: 83.33333333%
        }
        .offset-xl-11 {
            margin-left: 91.66666667%
        }
        .g-xl-0,
        .gx-xl-0 {
            --bs-gutter-x: 0
        }
        .g-xl-0,
        .gy-xl-0 {
            --bs-gutter-y: 0
        }
        .g-xl-1,
        .gx-xl-1 {
            --bs-gutter-x: 0.25rem
        }
        .g-xl-1,
        .gy-xl-1 {
            --bs-gutter-y: 0.25rem
        }
        .g-xl-2,
        .gx-xl-2 {
            --bs-gutter-x: 0.5rem
        }
        .g-xl-2,
        .gy-xl-2 {
            --bs-gutter-y: 0.5rem
        }
        .g-xl-3,
        .gx-xl-3 {
            --bs-gutter-x: 1rem
        }
        .g-xl-3,
        .gy-xl-3 {
            --bs-gutter-y: 1rem
        }
        .g-xl-4,
        .gx-xl-4 {
            --bs-gutter-x: 1.5rem
        }
        .g-xl-4,
        .gy-xl-4 {
            --bs-gutter-y: 1.5rem
        }
        .g-xl-5,
        .gx-xl-5 {
            --bs-gutter-x: 3rem
        }
        .g-xl-5,
        .gy-xl-5 {
            --bs-gutter-y: 3rem
        }
    }
    @media (min-width:1400px) {
        .col-xxl-auto {
            flex: 0 0 auto;
            width: auto
        }
        .col-xxl-1 {
            flex: 0 0 auto;
            width: 8.33333333%
        }
        .col-xxl-2 {
            flex: 0 0 auto;
            width: 16.66666667%
        }
        .col-xxl-3 {
            flex: 0 0 auto;
            width: 25%
        }
        .col-xxl-4 {
            flex: 0 0 auto;
            width: 33.33333333%
        }
        .col-xxl-5 {
            flex: 0 0 auto;
            width: 41.66666667%
        }
        .col-xxl-6 {
            flex: 0 0 auto;
            width: 50%
        }
        .col-xxl-7 {
            flex: 0 0 auto;
            width: 58.33333333%
        }
        .col-xxl-8 {
            flex: 0 0 auto;
            width: 66.66666667%
        }
        .col-xxl-9 {
            flex: 0 0 auto;
            width: 75%
        }
        .col-xxl-10 {
            flex: 0 0 auto;
            width: 83.33333333%
        }
        .col-xxl-11 {
            flex: 0 0 auto;
            width: 91.66666667%
        }
        .col-xxl-12 {
            flex: 0 0 auto;
            width: 100%
        }
        .offset-xxl-0 {
            margin-left: 0
        }
        .offset-xxl-1 {
            margin-left: 8.33333333%
        }
        .offset-xxl-2 {
            margin-left: 16.66666667%
        }
        .offset-xxl-3 {
            margin-left: 25%
        }
        .offset-xxl-4 {
            margin-left: 33.33333333%
        }
        .offset-xxl-5 {
            margin-left: 41.66666667%
        }
        .offset-xxl-6 {
            margin-left: 50%
        }
        .offset-xxl-7 {
            margin-left: 58.33333333%
        }
        .offset-xxl-8 {
            margin-left: 66.66666667%
        }
        .offset-xxl-9 {
            margin-left: 75%
        }
        .offset-xxl-10 {
            margin-left: 83.33333333%
        }
        .offset-xxl-11 {
            margin-left: 91.66666667%
        }
        .g-xxl-0,
        .gx-xxl-0 {
            --bs-gutter-x: 0
        }
        .g-xxl-0,
        .gy-xxl-0 {
            --bs-gutter-y: 0
        }
        .g-xxl-1,
        .gx-xxl-1 {
            --bs-gutter-x: 0.25rem
        }
        .g-xxl-1,
        .gy-xxl-1 {
            --bs-gutter-y: 0.25rem
        }
        .g-xxl-2,
        .gx-xxl-2 {
            --bs-gutter-x: 0.5rem
        }
        .g-xxl-2,
        .gy-xxl-2 {
            --bs-gutter-y: 0.5rem
        }
        .g-xxl-3,
        .gx-xxl-3 {
            --bs-gutter-x: 1rem
        }
        .g-xxl-3,
        .gy-xxl-3 {
            --bs-gutter-y: 1rem
        }
        .g-xxl-4,
        .gx-xxl-4 {
            --bs-gutter-x: 1.5rem
        }
        .g-xxl-4,
        .gy-xxl-4 {
            --bs-gutter-y: 1.5rem
        }
        .g-xxl-5,
        .gx-xxl-5 {
            --bs-gutter-x: 3rem
        }
        .g-xxl-5,
        .gy-xxl-5 {
            --bs-gutter-y: 3rem
        }
    }
    .table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #212529;
        --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
        --bs-table-active-color: #212529;
        --bs-table-active-bg: rgba(0, 0, 0, 0.1);
        --bs-table-hover-color: #212529;
        --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
        vertical-align: top;
        border-color: #dee2e6
    }
    .table>:not(caption)>*>* {
        padding: .5rem .5rem;
        background-color: var(--bs-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
    }
    .table>tbody {
        vertical-align: inherit
    }
    .table>thead {
        vertical-align: bottom
    }
    .table>:not(:last-child)>:last-child>* {
        border-bottom-color: currentColor
    }
    .caption-top {
        caption-side: top
    }
    .table-sm>:not(caption)>*>* {
        padding: .25rem .25rem
    }
    .table-bordered>:not(caption)>* {
        border-width: 1px 0
    }
    .table-bordered>:not(caption)>*>* {
        border-width: 0 1px
    }
    .table-borderless>:not(caption)>*>* {
        border-bottom-width: 0
    }
    .table-striped>tbody>tr:nth-of-type(odd) {
        --bs-table-accent-bg: var(--bs-table-striped-bg);
        color: var(--bs-table-striped-color)
    }
    .table-active {
        --bs-table-accent-bg: var(--bs-table-active-bg);
        color: var(--bs-table-active-color)
    }
    .table-hover>tbody>tr:hover {
        --bs-table-accent-bg: var(--bs-table-hover-bg);
        color: var(--bs-table-hover-color)
    }
    .table-primary {
        --bs-table-bg: #cfe2ff;
        --bs-table-striped-bg: #c5d7f2;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #bacbe6;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #bfd1ec;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #bacbe6
    }
    .table-secondary {
        --bs-table-bg: #e2e3e5;
        --bs-table-striped-bg: #d7d8da;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #cbccce;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #d1d2d4;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #cbccce
    }
    .table-success {
        --bs-table-bg: #d1e7dd;
        --bs-table-striped-bg: #c7dbd2;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #bcd0c7;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #c1d6cc;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #bcd0c7
    }
    .table-info {
        --bs-table-bg: #cff4fc;
        --bs-table-striped-bg: #c5e8ef;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #badce3;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #bfe2e9;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #badce3
    }
    .table-warning {
        --bs-table-bg: #fff3cd;
        --bs-table-striped-bg: #f2e7c3;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #e6dbb9;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #ece1be;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #e6dbb9
    }
    .table-danger {
        --bs-table-bg: #f8d7da;
        --bs-table-striped-bg: #eccccf;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #dfc2c4;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #e5c7ca;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #dfc2c4
    }
    .table-light {
        --bs-table-bg: #f8f9fa;
        --bs-table-striped-bg: #ecedee;
        --bs-table-striped-color: #000;
        --bs-table-active-bg: #dfe0e1;
        --bs-table-active-color: #000;
        --bs-table-hover-bg: #e5e6e7;
        --bs-table-hover-color: #000;
        color: #000;
        border-color: #dfe0e1
    }
    .table-dark {
        --bs-table-bg: #212529;
        --bs-table-striped-bg: #2c3034;
        --bs-table-striped-color: #fff;
        --bs-table-active-bg: #373b3e;
        --bs-table-active-color: #fff;
        --bs-table-hover-bg: #323539;
        --bs-table-hover-color: #fff;
        color: #fff;
        border-color: #373b3e
    }
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }
    @media (max-width:575.98px) {
        .table-responsive-sm {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch
        }
    }
    @media (max-width:767.98px) {
        .table-responsive-md {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch
        }
    }
    @media (max-width:991.98px) {
        .table-responsive-lg {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch
        }
    }
    @media (max-width:1199.98px) {
        .table-responsive-xl {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch
        }
    }
    @media (max-width:1399.98px) {
        .table-responsive-xxl {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch
        }
    }
    .form-label {
        margin-bottom: .5rem
    }
    .col-form-label {
        padding-top: calc(.375rem + 1px);
        padding-bottom: calc(.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5
    }
    .col-form-label-lg {
        padding-top: calc(.5rem + 1px);
        padding-bottom: calc(.5rem + 1px);
        font-size: 1.25rem
    }
    .col-form-label-sm {
        padding-top: calc(.25rem + 1px);
        padding-bottom: calc(.25rem + 1px);
        font-size: .875rem
    }
    .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }
    .align-baseline {
        vertical-align: baseline!important
    }
    .align-top {
        vertical-align: top!important
    }
    .align-middle {
        vertical-align: middle!important
    }
    .align-bottom {
        vertical-align: bottom!important
    }
    .align-text-bottom {
        vertical-align: text-bottom!important
    }
    .align-text-top {
        vertical-align: text-top!important
    }
    .float-start {
        float: left!important
    }
    .float-end {
        float: right!important
    }
    .float-none {
        float: none!important
    }
    .overflow-auto {
        overflow: auto!important
    }
    .overflow-hidden {
        overflow: hidden!important
    }
    .overflow-visible {
        overflow: visible!important
    }
    .overflow-scroll {
        overflow: scroll!important
    }
    .d-inline {
        display: inline!important
    }
    .d-inline-block {
        display: inline-block!important
    }
    .d-block {
        display: block!important
    }
    .d-grid {
        display: grid!important
    }
    .d-table {
        display: table!important
    }
    .d-table-row {
        display: table-row!important
    }
    .d-table-cell {
        display: table-cell!important
    }
    .d-flex {
        display: flex!important
    }
    .d-inline-flex {
        display: inline-flex!important
    }
    .d-none {
        display: none!important
    }
    .shadow {
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important
    }
    .shadow-sm {
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important
    }
    .shadow-lg {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175)!important
    }
    .shadow-none {
        box-shadow: none!important
    }
    .position-static {
        position: static!important
    }
    .position-relative {
        position: relative!important
    }
    .position-absolute {
        position: absolute!important
    }
    .position-fixed {
        position: fixed!important
    }
    .position-sticky {
        position: -webkit-sticky!important;
        position: sticky!important
    }
    .top-0 {
        top: 0!important
    }
    .top-50 {
        top: 50%!important
    }
    .top-100 {
        top: 100%!important
    }
    .bottom-0 {
        bottom: 0!important
    }
    .bottom-50 {
        bottom: 50%!important
    }
    .bottom-100 {
        bottom: 100%!important
    }
    .start-0 {
        left: 0!important
    }
    .start-50 {
        left: 50%!important
    }
    .start-100 {
        left: 100%!important
    }
    .end-0 {
        right: 0!important
    }
    .end-50 {
        right: 50%!important
    }
    .end-100 {
        right: 100%!important
    }
    .translate-middle {
        transform: translate(-50%, -50%)!important
    }
    .translate-middle-x {
        transform: translateX(-50%)!important
    }
    .translate-middle-y {
        transform: translateY(-50%)!important
    }
    .border {
        border: 1px solid #dee2e6!important
    }
    .border-0 {
        border: 0!important
    }
    .border-top {
        border-top: 1px solid #dee2e6!important
    }
    .border-top-0 {
        border-top: 0!important
    }
    .border-end {
        border-right: 1px solid #dee2e6!important
    }
    .border-end-0 {
        border-right: 0!important
    }
    .border-bottom {
        border-bottom: 1px solid #dee2e6!important
    }
    .border-bottom-0 {
        border-bottom: 0!important
    }
    .border-start {
        border-left: 1px solid #dee2e6!important
    }
    .border-start-0 {
        border-left: 0!important
    }
    .border-primary {
        border-color: #0d6efd!important
    }
    .border-secondary {
        border-color: #6c757d!important
    }
    .border-success {
        border-color: #198754!important
    }
    .border-info {
        border-color: #0dcaf0!important
    }
    .border-warning {
        border-color: #ffc107!important
    }
    .border-danger {
        border-color: #dc3545!important
    }
    .border-light {
        border-color: #f8f9fa!important
    }
    .border-dark {
        border-color: #212529!important
    }
    .border-white {
        border-color: #fff!important
    }
    .border-1 {
        border-width: 1px!important
    }
    .border-2 {
        border-width: 2px!important
    }
    .border-3 {
        border-width: 3px!important
    }
    .border-4 {
        border-width: 4px!important
    }
    .border-5 {
        border-width: 5px!important
    }
    .w-25 {
        width: 25%!important
    }
    .w-50 {
        width: 50%!important
    }
    .w-75 {
        width: 75%!important
    }
    .w-100 {
        width: 100%!important
    }
    .w-auto {
        width: auto!important
    }
    .mw-100 {
        max-width: 100%!important
    }
    .vw-100 {
        width: 100vw!important
    }
    .min-vw-100 {
        min-width: 100vw!important
    }
    .h-25 {
        height: 25%!important
    }
    .h-50 {
        height: 50%!important
    }
    .h-75 {
        height: 75%!important
    }
    .h-100 {
        height: 100%!important
    }
    .h-auto {
        height: auto!important
    }
    .mh-100 {
        max-height: 100%!important
    }
    .vh-100 {
        height: 100vh!important
    }
    .min-vh-100 {
        min-height: 100vh!important
    }
    .flex-fill {
        flex: 1 1 auto!important
    }
    .flex-row {
        flex-direction: row!important
    }
    .flex-column {
        flex-direction: column!important
    }
    .flex-row-reverse {
        flex-direction: row-reverse!important
    }
    .flex-column-reverse {
        flex-direction: column-reverse!important
    }
    .flex-grow-0 {
        flex-grow: 0!important
    }
    .flex-grow-1 {
        flex-grow: 1!important
    }
    .flex-shrink-0 {
        flex-shrink: 0!important
    }
    .flex-shrink-1 {
        flex-shrink: 1!important
    }
    .flex-wrap {
        flex-wrap: wrap!important
    }
    .flex-nowrap {
        flex-wrap: nowrap!important
    }
    .flex-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }
    .gap-0 {
        gap: 0!important
    }
    .gap-1 {
        gap: .25rem!important
    }
    .gap-2 {
        gap: .5rem!important
    }
    .gap-3 {
        gap: 1rem!important
    }
    .gap-4 {
        gap: 1.5rem!important
    }
    .gap-5 {
        gap: 3rem!important
    }
    .justify-content-start {
        justify-content: flex-start!important
    }
    .justify-content-end {
        justify-content: flex-end!important
    }
    .justify-content-center {
        justify-content: center!important
    }
    .justify-content-between {
        justify-content: space-between!important
    }
    .justify-content-around {
        justify-content: space-around!important
    }
    .justify-content-evenly {
        justify-content: space-evenly!important
    }
    .align-items-start {
        align-items: flex-start!important
    }
    .align-items-end {
        align-items: flex-end!important
    }
    .align-items-center {
        align-items: center!important
    }
    .align-items-baseline {
        align-items: baseline!important
    }
    .align-items-stretch {
        align-items: stretch!important
    }
    .align-content-start {
        align-content: flex-start!important
    }
    .align-content-end {
        align-content: flex-end!important
    }
    .align-content-center {
        align-content: center!important
    }
    .align-content-between {
        align-content: space-between!important
    }
    .align-content-around {
        align-content: space-around!important
    }
    .align-content-stretch {
        align-content: stretch!important
    }
    .align-self-auto {
        align-self: auto!important
    }
    .align-self-start {
        align-self: flex-start!important
    }
    .align-self-end {
        align-self: flex-end!important
    }
    .align-self-center {
        align-self: center!important
    }
    .align-self-baseline {
        align-self: baseline!important
    }
    .align-self-stretch {
        align-self: stretch!important
    }
    .order-first {
        order: -1!important
    }
    .order-0 {
        order: 0!important
    }
    .order-1 {
        order: 1!important
    }
    .order-2 {
        order: 2!important
    }
    .order-3 {
        order: 3!important
    }
    .order-4 {
        order: 4!important
    }
    .order-5 {
        order: 5!important
    }
    .order-last {
        order: 6!important
    }
    .m-0 {
        margin: 0!important
    }
    .m-1 {
        margin: .25rem!important
    }
    .m-2 {
        margin: .5rem!important
    }
    .m-3 {
        margin: 1rem!important
    }
    .m-4 {
        margin: 1.5rem!important
    }
    .m-5 {
        margin: 3rem!important
    }
    .m-auto {
        margin: auto!important
    }
    .mx-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }
    .mx-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }
    .mx-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }
    .mx-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }
    .mx-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }
    .mx-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }
    .mx-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }
    .my-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }
    .my-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }
    .my-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }
    .my-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }
    .my-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }
    .my-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }
    .my-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }
    .mt-0 {
        margin-top: 0!important
    }
    .mt-1 {
        margin-top: .25rem!important
    }
    .mt-2 {
        margin-top: .5rem!important
    }
    .mt-3 {
        margin-top: 1rem!important
    }
    .mt-4 {
        margin-top: 1.5rem!important
    }
    .mt-5 {
        margin-top: 3rem!important
    }
    .mt-auto {
        margin-top: auto!important
    }
    .me-0 {
        margin-right: 0!important
    }
    .me-1 {
        margin-right: .25rem!important
    }
    .me-2 {
        margin-right: .5rem!important
    }
    .me-3 {
        margin-right: 1rem!important
    }
    .me-4 {
        margin-right: 1.5rem!important
    }
    .me-5 {
        margin-right: 3rem!important
    }
    .me-auto {
        margin-right: auto!important
    }
    .mb-0 {
        margin-bottom: 0!important
    }
    .mb-1 {
        margin-bottom: .25rem!important
    }
    .mb-2 {
        margin-bottom: .5rem!important
    }
    .mb-3 {
        margin-bottom: 1rem!important
    }
    .mb-4 {
        margin-bottom: 1.5rem!important
    }
    .mb-5 {
        margin-bottom: 3rem!important
    }
    .mb-auto {
        margin-bottom: auto!important
    }
    .ms-0 {
        margin-left: 0!important
    }
    .ms-1 {
        margin-left: .25rem!important
    }
    .ms-2 {
        margin-left: .5rem!important
    }
    .ms-3 {
        margin-left: 1rem!important
    }
    .ms-4 {
        margin-left: 1.5rem!important
    }
    .ms-5 {
        margin-left: 3rem!important
    }
    .ms-auto {
        margin-left: auto!important
    }
    .p-0 {
        padding: 0!important
    }
    .p-1 {
        padding: .25rem!important
    }
    .p-2 {
        padding: .5rem!important
    }
    .p-3 {
        padding: 1rem!important
    }
    .p-4 {
        padding: 1.5rem!important
    }
    .p-5 {
        padding: 3rem!important
    }
    .px-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }
    .px-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }
    .px-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }
    .px-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }
    .px-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }
    .px-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }
    .py-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }
    .py-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }
    .py-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }
    .py-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }
    .py-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }
    .py-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }
    .pt-0 {
        padding-top: 0!important
    }
    .pt-1 {
        padding-top: .25rem!important
    }
    .pt-2 {
        padding-top: .5rem!important
    }
    .pt-3 {
        padding-top: 1rem!important
    }
    .pt-4 {
        padding-top: 1.5rem!important
    }
    .pt-5 {
        padding-top: 3rem!important
    }
    .pe-0 {
        padding-right: 0!important
    }
    .pe-1 {
        padding-right: .25rem!important
    }
    .pe-2 {
        padding-right: .5rem!important
    }
    .pe-3 {
        padding-right: 1rem!important
    }
    .pe-4 {
        padding-right: 1.5rem!important
    }
    .pe-5 {
        padding-right: 3rem!important
    }
    .pb-0 {
        padding-bottom: 0!important
    }
    .pb-1 {
        padding-bottom: .25rem!important
    }
    .pb-2 {
        padding-bottom: .5rem!important
    }
    .pb-3 {
        padding-bottom: 1rem!important
    }
    .pb-4 {
        padding-bottom: 1.5rem!important
    }
    .pb-5 {
        padding-bottom: 3rem!important
    }
    .ps-0 {
        padding-left: 0!important
    }
    .ps-1 {
        padding-left: .25rem!important
    }
    .ps-2 {
        padding-left: .5rem!important
    }
    .ps-3 {
        padding-left: 1rem!important
    }
    .ps-4 {
        padding-left: 1.5rem!important
    }
    .ps-5 {
        padding-left: 3rem!important
    }
    .font-monospace {
        font-family: var(--bs-font-monospace)!important
    }
    .fs-1 {
        font-size: calc(1.375rem + 1.5vw)!important
    }
    .fs-2 {
        font-size: calc(1.325rem + .9vw)!important
    }
    .fs-3 {
        font-size: calc(1.3rem + .6vw)!important
    }
    .fs-4 {
        font-size: calc(1.275rem + .3vw)!important
    }
    .fs-5 {
        font-size: 1.25rem!important
    }
    .fs-6 {
        font-size: 1rem!important
    }
    .fst-italic {
        font-style: italic!important
    }
    .fst-normal {
        font-style: normal!important
    }
    .fw-light {
        font-weight: 300!important
    }
    .fw-lighter {
        font-weight: lighter!important
    }
    .fw-normal {
        font-weight: 400!important
    }
    .fw-bold {
        font-weight: 700!important
    }
    .fw-bolder {
        font-weight: bolder!important
    }
    .lh-1 {
        line-height: 1!important
    }
    .lh-sm {
        line-height: 1.25!important
    }
    .lh-base {
        line-height: 1.5!important
    }
    .lh-lg {
        line-height: 2!important
    }
    .text-start {
        text-align: left!important
    }
    .text-end {
        text-align: right!important
    }
    .text-center {
        text-align: center!important
    }
    .text-decoration-none {
        text-decoration: none!important
    }
    .text-decoration-underline {
        text-decoration: underline!important
    }
    .text-decoration-line-through {
        text-decoration: line-through!important
    }
    .text-lowercase {
        text-transform: lowercase!important
    }
    .text-uppercase {
        text-transform: uppercase!important
    }
    .text-capitalize {
        text-transform: capitalize!important
    }
    .text-wrap {
        white-space: normal!important
    }
    .text-nowrap {
        white-space: nowrap!important
    }
    .text-break {
        word-wrap: break-word!important;
        word-break: break-word!important
    }
    .text-secondary {
        color: #6c757d!important
    }
    .text-success {
        color: #198754!important
    }
    .text-info {
        color: #0dcaf0!important
    }
    .text-warning {
        color: #ffc107!important
    }
    .text-danger {
        color: #dc3545!important
    }
    .text-light {
        color: #f8f9fa!important
    }
    .text-dark {
        color: #212529!important
    }
    .text-white {
        color: #fff!important
    }
    .text-body {
        color: #212529!important
    }
    .text-muted {
        color: #6c757d!important
    }
    .text-black-50 {
        color: rgba(0, 0, 0, .5)!important
    }
    .text-white-50 {
        color: rgba(255, 255, 255, .5)!important
    }
    .text-reset {
        color: inherit!important
    }
    .bg-primary {
        background-color: #0d6efd!important
    }
    .bg-secondary {
        background-color: #6c757d!important
    }
    .bg-success {
        background-color: #198754!important
    }
    .bg-info {
        background-color: #0dcaf0!important
    }
    .bg-warning {
        background-color: #ffc107!important
    }
    .bg-danger {
        background-color: #dc3545!important
    }
    .bg-light {
        background-color: #f8f9fa!important
    }
    .bg-dark {
        background-color: #212529!important
    }
    .bg-body {
        background-color: #fff!important
    }
    .bg-white {
        background-color: #fff!important
    }
    .bg-transparent {
        background-color: transparent!important
    }
    .bg-gradient {
        background-image: var(--bs-gradient)!important
    }
    .user-select-all {
        -webkit-user-select: all!important;
        -moz-user-select: all!important;
        user-select: all!important
    }
    .user-select-auto {
        -webkit-user-select: auto!important;
        -moz-user-select: auto!important;
        user-select: auto!important
    }
    .user-select-none {
        -webkit-user-select: none!important;
        -moz-user-select: none!important;
        user-select: none!important
    }
    .pe-none {
        pointer-events: none!important
    }
    .pe-auto {
        pointer-events: auto!important
    }
    .rounded {
        border-radius: .25rem!important
    }
    .rounded-0 {
        border-radius: 0!important
    }
    .rounded-1 {
        border-radius: .2rem!important
    }
    .rounded-2 {
        border-radius: .25rem!important
    }
    .rounded-3 {
        border-radius: .3rem!important
    }
    .rounded-circle {
        border-radius: 50%!important
    }
    .rounded-pill {
        border-radius: 50rem!important
    }
    .rounded-top {
        border-top-left-radius: .25rem!important;
        border-top-right-radius: .25rem!important
    }
    .rounded-end {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important
    }
    .rounded-bottom {
        border-bottom-right-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important
    }
    .rounded-start {
        border-bottom-left-radius: .25rem!important;
        border-top-left-radius: .25rem!important
    }
    .visible {
        visibility: visible!important
    }
    .invisible {
        visibility: hidden!important
    }
    @media (min-width:576px) {
        .float-sm-start {
            float: left!important
        }
        .float-sm-end {
            float: right!important
        }
        .float-sm-none {
            float: none!important
        }
        .d-sm-inline {
            display: inline!important
        }
        .d-sm-inline-block {
            display: inline-block!important
        }
        .d-sm-block {
            display: block!important
        }
        .d-sm-grid {
            display: grid!important
        }
        .d-sm-table {
            display: table!important
        }
        .d-sm-table-row {
            display: table-row!important
        }
        .d-sm-table-cell {
            display: table-cell!important
        }
        .d-sm-flex {
            display: flex!important
        }
        .d-sm-inline-flex {
            display: inline-flex!important
        }
        .d-sm-none {
            display: none!important
        }
        .flex-sm-fill {
            flex: 1 1 auto!important
        }
        .flex-sm-row {
            flex-direction: row!important
        }
        .flex-sm-column {
            flex-direction: column!important
        }
        .flex-sm-row-reverse {
            flex-direction: row-reverse!important
        }
        .flex-sm-column-reverse {
            flex-direction: column-reverse!important
        }
        .flex-sm-grow-0 {
            flex-grow: 0!important
        }
        .flex-sm-grow-1 {
            flex-grow: 1!important
        }
        .flex-sm-shrink-0 {
            flex-shrink: 0!important
        }
        .flex-sm-shrink-1 {
            flex-shrink: 1!important
        }
        .flex-sm-wrap {
            flex-wrap: wrap!important
        }
        .flex-sm-nowrap {
            flex-wrap: nowrap!important
        }
        .flex-sm-wrap-reverse {
            flex-wrap: wrap-reverse!important
        }
        .gap-sm-0 {
            gap: 0!important
        }
        .gap-sm-1 {
            gap: .25rem!important
        }
        .gap-sm-2 {
            gap: .5rem!important
        }
        .gap-sm-3 {
            gap: 1rem!important
        }
        .gap-sm-4 {
            gap: 1.5rem!important
        }
        .gap-sm-5 {
            gap: 3rem!important
        }
        .justify-content-sm-start {
            justify-content: flex-start!important
        }
        .justify-content-sm-end {
            justify-content: flex-end!important
        }
        .justify-content-sm-center {
            justify-content: center!important
        }
        .justify-content-sm-between {
            justify-content: space-between!important
        }
        .justify-content-sm-around {
            justify-content: space-around!important
        }
        .justify-content-sm-evenly {
            justify-content: space-evenly!important
        }
        .align-items-sm-start {
            align-items: flex-start!important
        }
        .align-items-sm-end {
            align-items: flex-end!important
        }
        .align-items-sm-center {
            align-items: center!important
        }
        .align-items-sm-baseline {
            align-items: baseline!important
        }
        .align-items-sm-stretch {
            align-items: stretch!important
        }
        .align-content-sm-start {
            align-content: flex-start!important
        }
        .align-content-sm-end {
            align-content: flex-end!important
        }
        .align-content-sm-center {
            align-content: center!important
        }
        .align-content-sm-between {
            align-content: space-between!important
        }
        .align-content-sm-around {
            align-content: space-around!important
        }
        .align-content-sm-stretch {
            align-content: stretch!important
        }
        .align-self-sm-auto {
            align-self: auto!important
        }
        .align-self-sm-start {
            align-self: flex-start!important
        }
        .align-self-sm-end {
            align-self: flex-end!important
        }
        .align-self-sm-center {
            align-self: center!important
        }
        .align-self-sm-baseline {
            align-self: baseline!important
        }
        .align-self-sm-stretch {
            align-self: stretch!important
        }
        .order-sm-first {
            order: -1!important
        }
        .order-sm-0 {
            order: 0!important
        }
        .order-sm-1 {
            order: 1!important
        }
        .order-sm-2 {
            order: 2!important
        }
        .order-sm-3 {
            order: 3!important
        }
        .order-sm-4 {
            order: 4!important
        }
        .order-sm-5 {
            order: 5!important
        }
        .order-sm-last {
            order: 6!important
        }
        .m-sm-0 {
            margin: 0!important
        }
        .m-sm-1 {
            margin: .25rem!important
        }
        .m-sm-2 {
            margin: .5rem!important
        }
        .m-sm-3 {
            margin: 1rem!important
        }
        .m-sm-4 {
            margin: 1.5rem!important
        }
        .m-sm-5 {
            margin: 3rem!important
        }
        .m-sm-auto {
            margin: auto!important
        }
        .mx-sm-0 {
            margin-right: 0!important;
            margin-left: 0!important
        }
        .mx-sm-1 {
            margin-right: .25rem!important;
            margin-left: .25rem!important
        }
        .mx-sm-2 {
            margin-right: .5rem!important;
            margin-left: .5rem!important
        }
        .mx-sm-3 {
            margin-right: 1rem!important;
            margin-left: 1rem!important
        }
        .mx-sm-4 {
            margin-right: 1.5rem!important;
            margin-left: 1.5rem!important
        }
        .mx-sm-5 {
            margin-right: 3rem!important;
            margin-left: 3rem!important
        }
        .mx-sm-auto {
            margin-right: auto!important;
            margin-left: auto!important
        }
        .my-sm-0 {
            margin-top: 0!important;
            margin-bottom: 0!important
        }
        .my-sm-1 {
            margin-top: .25rem!important;
            margin-bottom: .25rem!important
        }
        .my-sm-2 {
            margin-top: .5rem!important;
            margin-bottom: .5rem!important
        }
        .my-sm-3 {
            margin-top: 1rem!important;
            margin-bottom: 1rem!important
        }
        .my-sm-4 {
            margin-top: 1.5rem!important;
            margin-bottom: 1.5rem!important
        }
        .my-sm-5 {
            margin-top: 3rem!important;
            margin-bottom: 3rem!important
        }
        .my-sm-auto {
            margin-top: auto!important;
            margin-bottom: auto!important
        }
        .mt-sm-0 {
            margin-top: 0!important
        }
        .mt-sm-1 {
            margin-top: .25rem!important
        }
        .mt-sm-2 {
            margin-top: .5rem!important
        }
        .mt-sm-3 {
            margin-top: 1rem!important
        }
        .mt-sm-4 {
            margin-top: 1.5rem!important
        }
        .mt-sm-5 {
            margin-top: 3rem!important
        }
        .mt-sm-auto {
            margin-top: auto!important
        }
        .me-sm-0 {
            margin-right: 0!important
        }
        .me-sm-1 {
            margin-right: .25rem!important
        }
        .me-sm-2 {
            margin-right: .5rem!important
        }
        .me-sm-3 {
            margin-right: 1rem!important
        }
        .me-sm-4 {
            margin-right: 1.5rem!important
        }
        .me-sm-5 {
            margin-right: 3rem!important
        }
        .me-sm-auto {
            margin-right: auto!important
        }
        .mb-sm-0 {
            margin-bottom: 0!important
        }
        .mb-sm-1 {
            margin-bottom: .25rem!important
        }
        .mb-sm-2 {
            margin-bottom: .5rem!important
        }
        .mb-sm-3 {
            margin-bottom: 1rem!important
        }
        .mb-sm-4 {
            margin-bottom: 1.5rem!important
        }
        .mb-sm-5 {
            margin-bottom: 3rem!important
        }
        .mb-sm-auto {
            margin-bottom: auto!important
        }
        .ms-sm-0 {
            margin-left: 0!important
        }
        .ms-sm-1 {
            margin-left: .25rem!important
        }
        .ms-sm-2 {
            margin-left: .5rem!important
        }
        .ms-sm-3 {
            margin-left: 1rem!important
        }
        .ms-sm-4 {
            margin-left: 1.5rem!important
        }
        .ms-sm-5 {
            margin-left: 3rem!important
        }
        .ms-sm-auto {
            margin-left: auto!important
        }
        .p-sm-0 {
            padding: 0!important
        }
        .p-sm-1 {
            padding: .25rem!important
        }
        .p-sm-2 {
            padding: .5rem!important
        }
        .p-sm-3 {
            padding: 1rem!important
        }
        .p-sm-4 {
            padding: 1.5rem!important
        }
        .p-sm-5 {
            padding: 3rem!important
        }
        .px-sm-0 {
            padding-right: 0!important;
            padding-left: 0!important
        }
        .px-sm-1 {
            padding-right: .25rem!important;
            padding-left: .25rem!important
        }
        .px-sm-2 {
            padding-right: .5rem!important;
            padding-left: .5rem!important
        }
        .px-sm-3 {
            padding-right: 1rem!important;
            padding-left: 1rem!important
        }
        .px-sm-4 {
            padding-right: 1.5rem!important;
            padding-left: 1.5rem!important
        }
        .px-sm-5 {
            padding-right: 3rem!important;
            padding-left: 3rem!important
        }
        .py-sm-0 {
            padding-top: 0!important;
            padding-bottom: 0!important
        }
        .py-sm-1 {
            padding-top: .25rem!important;
            padding-bottom: .25rem!important
        }
        .py-sm-2 {
            padding-top: .5rem!important;
            padding-bottom: .5rem!important
        }
        .py-sm-3 {
            padding-top: 1rem!important;
            padding-bottom: 1rem!important
        }
        .py-sm-4 {
            padding-top: 1.5rem!important;
            padding-bottom: 1.5rem!important
        }
        .py-sm-5 {
            padding-top: 3rem!important;
            padding-bottom: 3rem!important
        }
        .pt-sm-0 {
            padding-top: 0!important
        }
        .pt-sm-1 {
            padding-top: .25rem!important
        }
        .pt-sm-2 {
            padding-top: .5rem!important
        }
        .pt-sm-3 {
            padding-top: 1rem!important
        }
        .pt-sm-4 {
            padding-top: 1.5rem!important
        }
        .pt-sm-5 {
            padding-top: 3rem!important
        }
        .pe-sm-0 {
            padding-right: 0!important
        }
        .pe-sm-1 {
            padding-right: .25rem!important
        }
        .pe-sm-2 {
            padding-right: .5rem!important
        }
        .pe-sm-3 {
            padding-right: 1rem!important
        }
        .pe-sm-4 {
            padding-right: 1.5rem!important
        }
        .pe-sm-5 {
            padding-right: 3rem!important
        }
        .pb-sm-0 {
            padding-bottom: 0!important
        }
        .pb-sm-1 {
            padding-bottom: .25rem!important
        }
        .pb-sm-2 {
            padding-bottom: .5rem!important
        }
        .pb-sm-3 {
            padding-bottom: 1rem!important
        }
        .pb-sm-4 {
            padding-bottom: 1.5rem!important
        }
        .pb-sm-5 {
            padding-bottom: 3rem!important
        }
        .ps-sm-0 {
            padding-left: 0!important
        }
        .ps-sm-1 {
            padding-left: .25rem!important
        }
        .ps-sm-2 {
            padding-left: .5rem!important
        }
        .ps-sm-3 {
            padding-left: 1rem!important
        }
        .ps-sm-4 {
            padding-left: 1.5rem!important
        }
        .ps-sm-5 {
            padding-left: 3rem!important
        }
        .text-sm-start {
            text-align: left!important
        }
        .text-sm-end {
            text-align: right!important
        }
        .text-sm-center {
            text-align: center!important
        }
    }
    @media (min-width:768px) {
        .float-md-start {
            float: left!important
        }
        .float-md-end {
            float: right!important
        }
        .float-md-none {
            float: none!important
        }
        .d-md-inline {
            display: inline!important
        }
        .d-md-inline-block {
            display: inline-block!important
        }
        .d-md-block {
            display: block!important
        }
        .d-md-grid {
            display: grid!important
        }
        .d-md-table {
            display: table!important
        }
        .d-md-table-row {
            display: table-row!important
        }
        .d-md-table-cell {
            display: table-cell!important
        }
        .d-md-flex {
            display: flex!important
        }
        .d-md-inline-flex {
            display: inline-flex!important
        }
        .d-md-none {
            display: none!important
        }
        .flex-md-fill {
            flex: 1 1 auto!important
        }
        .flex-md-row {
            flex-direction: row!important
        }
        .flex-md-column {
            flex-direction: column!important
        }
        .flex-md-row-reverse {
            flex-direction: row-reverse!important
        }
        .flex-md-column-reverse {
            flex-direction: column-reverse!important
        }
        .flex-md-grow-0 {
            flex-grow: 0!important
        }
        .flex-md-grow-1 {
            flex-grow: 1!important
        }
        .flex-md-shrink-0 {
            flex-shrink: 0!important
        }
        .flex-md-shrink-1 {
            flex-shrink: 1!important
        }
        .flex-md-wrap {
            flex-wrap: wrap!important
        }
        .flex-md-nowrap {
            flex-wrap: nowrap!important
        }
        .flex-md-wrap-reverse {
            flex-wrap: wrap-reverse!important
        }
        .gap-md-0 {
            gap: 0!important
        }
        .gap-md-1 {
            gap: .25rem!important
        }
        .gap-md-2 {
            gap: .5rem!important
        }
        .gap-md-3 {
            gap: 1rem!important
        }
        .gap-md-4 {
            gap: 1.5rem!important
        }
        .gap-md-5 {
            gap: 3rem!important
        }
        .justify-content-md-start {
            justify-content: flex-start!important
        }
        .justify-content-md-end {
            justify-content: flex-end!important
        }
        .justify-content-md-center {
            justify-content: center!important
        }
        .justify-content-md-between {
            justify-content: space-between!important
        }
        .justify-content-md-around {
            justify-content: space-around!important
        }
        .justify-content-md-evenly {
            justify-content: space-evenly!important
        }
        .align-items-md-start {
            align-items: flex-start!important
        }
        .align-items-md-end {
            align-items: flex-end!important
        }
        .align-items-md-center {
            align-items: center!important
        }
        .align-items-md-baseline {
            align-items: baseline!important
        }
        .align-items-md-stretch {
            align-items: stretch!important
        }
        .align-content-md-start {
            align-content: flex-start!important
        }
        .align-content-md-end {
            align-content: flex-end!important
        }
        .align-content-md-center {
            align-content: center!important
        }
        .align-content-md-between {
            align-content: space-between!important
        }
        .align-content-md-around {
            align-content: space-around!important
        }
        .align-content-md-stretch {
            align-content: stretch!important
        }
        .align-self-md-auto {
            align-self: auto!important
        }
        .align-self-md-start {
            align-self: flex-start!important
        }
        .align-self-md-end {
            align-self: flex-end!important
        }
        .align-self-md-center {
            align-self: center!important
        }
        .align-self-md-baseline {
            align-self: baseline!important
        }
        .align-self-md-stretch {
            align-self: stretch!important
        }
        .order-md-first {
            order: -1!important
        }
        .order-md-0 {
            order: 0!important
        }
        .order-md-1 {
            order: 1!important
        }
        .order-md-2 {
            order: 2!important
        }
        .order-md-3 {
            order: 3!important
        }
        .order-md-4 {
            order: 4!important
        }
        .order-md-5 {
            order: 5!important
        }
        .order-md-last {
            order: 6!important
        }
        .m-md-0 {
            margin: 0!important
        }
        .m-md-1 {
            margin: .25rem!important
        }
        .m-md-2 {
            margin: .5rem!important
        }
        .m-md-3 {
            margin: 1rem!important
        }
        .m-md-4 {
            margin: 1.5rem!important
        }
        .m-md-5 {
            margin: 3rem!important
        }
        .m-md-auto {
            margin: auto!important
        }
        .mx-md-0 {
            margin-right: 0!important;
            margin-left: 0!important
        }
        .mx-md-1 {
            margin-right: .25rem!important;
            margin-left: .25rem!important
        }
        .mx-md-2 {
            margin-right: .5rem!important;
            margin-left: .5rem!important
        }
        .mx-md-3 {
            margin-right: 1rem!important;
            margin-left: 1rem!important
        }
        .mx-md-4 {
            margin-right: 1.5rem!important;
            margin-left: 1.5rem!important
        }
        .mx-md-5 {
            margin-right: 3rem!important;
            margin-left: 3rem!important
        }
        .mx-md-auto {
            margin-right: auto!important;
            margin-left: auto!important
        }
        .my-md-0 {
            margin-top: 0!important;
            margin-bottom: 0!important
        }
        .my-md-1 {
            margin-top: .25rem!important;
            margin-bottom: .25rem!important
        }
        .my-md-2 {
            margin-top: .5rem!important;
            margin-bottom: .5rem!important
        }
        .my-md-3 {
            margin-top: 1rem!important;
            margin-bottom: 1rem!important
        }
        .my-md-4 {
            margin-top: 1.5rem!important;
            margin-bottom: 1.5rem!important
        }
        .my-md-5 {
            margin-top: 3rem!important;
            margin-bottom: 3rem!important
        }
        .my-md-auto {
            margin-top: auto!important;
            margin-bottom: auto!important
        }
        .mt-md-0 {
            margin-top: 0!important
        }
        .mt-md-1 {
            margin-top: .25rem!important
        }
        .mt-md-2 {
            margin-top: .5rem!important
        }
        .mt-md-3 {
            margin-top: 1rem!important
        }
        .mt-md-4 {
            margin-top: 1.5rem!important
        }
        .mt-md-5 {
            margin-top: 3rem!important
        }
        .mt-md-auto {
            margin-top: auto!important
        }
        .me-md-0 {
            margin-right: 0!important
        }
        .me-md-1 {
            margin-right: .25rem!important
        }
        .me-md-2 {
            margin-right: .5rem!important
        }
        .me-md-3 {
            margin-right: 1rem!important
        }
        .me-md-4 {
            margin-right: 1.5rem!important
        }
        .me-md-5 {
            margin-right: 3rem!important
        }
        .me-md-auto {
            margin-right: auto!important
        }
        .mb-md-0 {
            margin-bottom: 0!important
        }
        .mb-md-1 {
            margin-bottom: .25rem!important
        }
        .mb-md-2 {
            margin-bottom: .5rem!important
        }
        .mb-md-3 {
            margin-bottom: 1rem!important
        }
        .mb-md-4 {
            margin-bottom: 1.5rem!important
        }
        .mb-md-5 {
            margin-bottom: 3rem!important
        }
        .mb-md-auto {
            margin-bottom: auto!important
        }
        .ms-md-0 {
            margin-left: 0!important
        }
        .ms-md-1 {
            margin-left: .25rem!important
        }
        .ms-md-2 {
            margin-left: .5rem!important
        }
        .ms-md-3 {
            margin-left: 1rem!important
        }
        .ms-md-4 {
            margin-left: 1.5rem!important
        }
        .ms-md-5 {
            margin-left: 3rem!important
        }
        .ms-md-auto {
            margin-left: auto!important
        }
        .p-md-0 {
            padding: 0!important
        }
        .p-md-1 {
            padding: .25rem!important
        }
        .p-md-2 {
            padding: .5rem!important
        }
        .p-md-3 {
            padding: 1rem!important
        }
        .p-md-4 {
            padding: 1.5rem!important
        }
        .p-md-5 {
            padding: 3rem!important
        }
        .px-md-0 {
            padding-right: 0!important;
            padding-left: 0!important
        }
        .px-md-1 {
            padding-right: .25rem!important;
            padding-left: .25rem!important
        }
        .px-md-2 {
            padding-right: .5rem!important;
            padding-left: .5rem!important
        }
        .px-md-3 {
            padding-right: 1rem!important;
            padding-left: 1rem!important
        }
        .px-md-4 {
            padding-right: 1.5rem!important;
            padding-left: 1.5rem!important
        }
        .px-md-5 {
            padding-right: 3rem!important;
            padding-left: 3rem!important
        }
        .py-md-0 {
            padding-top: 0!important;
            padding-bottom: 0!important
        }
        .py-md-1 {
            padding-top: .25rem!important;
            padding-bottom: .25rem!important
        }
        .py-md-2 {
            padding-top: .5rem!important;
            padding-bottom: .5rem!important
        }
        .py-md-3 {
            padding-top: 1rem!important;
            padding-bottom: 1rem!important
        }
        .py-md-4 {
            padding-top: 1.5rem!important;
            padding-bottom: 1.5rem!important
        }
        .py-md-5 {
            padding-top: 3rem!important;
            padding-bottom: 3rem!important
        }
        .pt-md-0 {
            padding-top: 0!important
        }
        .pt-md-1 {
            padding-top: .25rem!important
        }
        .pt-md-2 {
            padding-top: .5rem!important
        }
        .pt-md-3 {
            padding-top: 1rem!important
        }
        .pt-md-4 {
            padding-top: 1.5rem!important
        }
        .pt-md-5 {
            padding-top: 3rem!important
        }
        .pe-md-0 {
            padding-right: 0!important
        }
        .pe-md-1 {
            padding-right: .25rem!important
        }
        .pe-md-2 {
            padding-right: .5rem!important
        }
        .pe-md-3 {
            padding-right: 1rem!important
        }
        .pe-md-4 {
            padding-right: 1.5rem!important
        }
        .pe-md-5 {
            padding-right: 3rem!important
        }
        .pb-md-0 {
            padding-bottom: 0!important
        }
        .pb-md-1 {
            padding-bottom: .25rem!important
        }
        .pb-md-2 {
            padding-bottom: .5rem!important
        }
        .pb-md-3 {
            padding-bottom: 1rem!important
        }
        .pb-md-4 {
            padding-bottom: 1.5rem!important
        }
        .pb-md-5 {
            padding-bottom: 3rem!important
        }
        .ps-md-0 {
            padding-left: 0!important
        }
        .ps-md-1 {
            padding-left: .25rem!important
        }
        .ps-md-2 {
            padding-left: .5rem!important
        }
        .ps-md-3 {
            padding-left: 1rem!important
        }
        .ps-md-4 {
            padding-left: 1.5rem!important
        }
        .ps-md-5 {
            padding-left: 3rem!important
        }
        .text-md-start {
            text-align: left!important
        }
        .text-md-end {
            text-align: right!important
        }
        .text-md-center {
            text-align: center!important
        }
    }
    @media (min-width:992px) {
        .float-lg-start {
            float: left!important
        }
        .float-lg-end {
            float: right!important
        }
        .float-lg-none {
            float: none!important
        }
        .d-lg-inline {
            display: inline!important
        }
        .d-lg-inline-block {
            display: inline-block!important
        }
        .d-lg-block {
            display: block!important
        }
        .d-lg-grid {
            display: grid!important
        }
        .d-lg-table {
            display: table!important
        }
        .d-lg-table-row {
            display: table-row!important
        }
        .d-lg-table-cell {
            display: table-cell!important
        }
        .d-lg-flex {
            display: flex!important
        }
        .d-lg-inline-flex {
            display: inline-flex!important
        }
        .d-lg-none {
            display: none!important
        }
        .flex-lg-fill {
            flex: 1 1 auto!important
        }
        .flex-lg-row {
            flex-direction: row!important
        }
        .flex-lg-column {
            flex-direction: column!important
        }
        .flex-lg-row-reverse {
            flex-direction: row-reverse!important
        }
        .flex-lg-column-reverse {
            flex-direction: column-reverse!important
        }
        .flex-lg-grow-0 {
            flex-grow: 0!important
        }
        .flex-lg-grow-1 {
            flex-grow: 1!important
        }
        .flex-lg-shrink-0 {
            flex-shrink: 0!important
        }
        .flex-lg-shrink-1 {
            flex-shrink: 1!important
        }
        .flex-lg-wrap {
            flex-wrap: wrap!important
        }
        .flex-lg-nowrap {
            flex-wrap: nowrap!important
        }
        .flex-lg-wrap-reverse {
            flex-wrap: wrap-reverse!important
        }
        .gap-lg-0 {
            gap: 0!important
        }
        .gap-lg-1 {
            gap: .25rem!important
        }
        .gap-lg-2 {
            gap: .5rem!important
        }
        .gap-lg-3 {
            gap: 1rem!important
        }
        .gap-lg-4 {
            gap: 1.5rem!important
        }
        .gap-lg-5 {
            gap: 3rem!important
        }
        .justify-content-lg-start {
            justify-content: flex-start!important
        }
        .justify-content-lg-end {
            justify-content: flex-end!important
        }
        .justify-content-lg-center {
            justify-content: center!important
        }
        .justify-content-lg-between {
            justify-content: space-between!important
        }
        .justify-content-lg-around {
            justify-content: space-around!important
        }
        .justify-content-lg-evenly {
            justify-content: space-evenly!important
        }
        .align-items-lg-start {
            align-items: flex-start!important
        }
        .align-items-lg-end {
            align-items: flex-end!important
        }
        .align-items-lg-center {
            align-items: center!important
        }
        .align-items-lg-baseline {
            align-items: baseline!important
        }
        .align-items-lg-stretch {
            align-items: stretch!important
        }
        .align-content-lg-start {
            align-content: flex-start!important
        }
        .align-content-lg-end {
            align-content: flex-end!important
        }
        .align-content-lg-center {
            align-content: center!important
        }
        .align-content-lg-between {
            align-content: space-between!important
        }
        .align-content-lg-around {
            align-content: space-around!important
        }
        .align-content-lg-stretch {
            align-content: stretch!important
        }
        .align-self-lg-auto {
            align-self: auto!important
        }
        .align-self-lg-start {
            align-self: flex-start!important
        }
        .align-self-lg-end {
            align-self: flex-end!important
        }
        .align-self-lg-center {
            align-self: center!important
        }
        .align-self-lg-baseline {
            align-self: baseline!important
        }
        .align-self-lg-stretch {
            align-self: stretch!important
        }
        .order-lg-first {
            order: -1!important
        }
        .order-lg-0 {
            order: 0!important
        }
        .order-lg-1 {
            order: 1!important
        }
        .order-lg-2 {
            order: 2!important
        }
        .order-lg-3 {
            order: 3!important
        }
        .order-lg-4 {
            order: 4!important
        }
        .order-lg-5 {
            order: 5!important
        }
        .order-lg-last {
            order: 6!important
        }
        .m-lg-0 {
            margin: 0!important
        }
        .m-lg-1 {
            margin: .25rem!important
        }
        .m-lg-2 {
            margin: .5rem!important
        }
        .m-lg-3 {
            margin: 1rem!important
        }
        .m-lg-4 {
            margin: 1.5rem!important
        }
        .m-lg-5 {
            margin: 3rem!important
        }
        .m-lg-auto {
            margin: auto!important
        }
        .mx-lg-0 {
            margin-right: 0!important;
            margin-left: 0!important
        }
        .mx-lg-1 {
            margin-right: .25rem!important;
            margin-left: .25rem!important
        }
        .mx-lg-2 {
            margin-right: .5rem!important;
            margin-left: .5rem!important
        }
        .mx-lg-3 {
            margin-right: 1rem!important;
            margin-left: 1rem!important
        }
        .mx-lg-4 {
            margin-right: 1.5rem!important;
            margin-left: 1.5rem!important
        }
        .mx-lg-5 {
            margin-right: 3rem!important;
            margin-left: 3rem!important
        }
        .mx-lg-auto {
            margin-right: auto!important;
            margin-left: auto!important
        }
        .my-lg-0 {
            margin-top: 0!important;
            margin-bottom: 0!important
        }
        .my-lg-1 {
            margin-top: .25rem!important;
            margin-bottom: .25rem!important
        }
        .my-lg-2 {
            margin-top: .5rem!important;
            margin-bottom: .5rem!important
        }
        .my-lg-3 {
            margin-top: 1rem!important;
            margin-bottom: 1rem!important
        }
        .my-lg-4 {
            margin-top: 1.5rem!important;
            margin-bottom: 1.5rem!important
        }
        .my-lg-5 {
            margin-top: 3rem!important;
            margin-bottom: 3rem!important
        }
        .my-lg-auto {
            margin-top: auto!important;
            margin-bottom: auto!important
        }
        .mt-lg-0 {
            margin-top: 0!important
        }
        .mt-lg-1 {
            margin-top: .25rem!important
        }
        .mt-lg-2 {
            margin-top: .5rem!important
        }
        .mt-lg-3 {
            margin-top: 1rem!important
        }
        .mt-lg-4 {
            margin-top: 1.5rem!important
        }
        .mt-lg-5 {
            margin-top: 3rem!important
        }
        .mt-lg-auto {
            margin-top: auto!important
        }
        .me-lg-0 {
            margin-right: 0!important
        }
        .me-lg-1 {
            margin-right: .25rem!important
        }
        .me-lg-2 {
            margin-right: .5rem!important
        }
        .me-lg-3 {
            margin-right: 1rem!important
        }
        .me-lg-4 {
            margin-right: 1.5rem!important
        }
        .me-lg-5 {
            margin-right: 3rem!important
        }
        .me-lg-auto {
            margin-right: auto!important
        }
        .mb-lg-0 {
            margin-bottom: 0!important
        }
        .mb-lg-1 {
            margin-bottom: .25rem!important
        }
        .mb-lg-2 {
            margin-bottom: .5rem!important
        }
        .mb-lg-3 {
            margin-bottom: 1rem!important
        }
        .mb-lg-4 {
            margin-bottom: 1.5rem!important
        }
        .mb-lg-5 {
            margin-bottom: 3rem!important
        }
        .mb-lg-auto {
            margin-bottom: auto!important
        }
        .ms-lg-0 {
            margin-left: 0!important
        }
        .ms-lg-1 {
            margin-left: .25rem!important
        }
        .ms-lg-2 {
            margin-left: .5rem!important
        }
        .ms-lg-3 {
            margin-left: 1rem!important
        }
        .ms-lg-4 {
            margin-left: 1.5rem!important
        }
        .ms-lg-5 {
            margin-left: 3rem!important
        }
        .ms-lg-auto {
            margin-left: auto!important
        }
        .p-lg-0 {
            padding: 0!important
        }
        .p-lg-1 {
            padding: .25rem!important
        }
        .p-lg-2 {
            padding: .5rem!important
        }
        .p-lg-3 {
            padding: 1rem!important
        }
        .p-lg-4 {
            padding: 1.5rem!important
        }
        .p-lg-5 {
            padding: 3rem!important
        }
        .px-lg-0 {
            padding-right: 0!important;
            padding-left: 0!important
        }
        .px-lg-1 {
            padding-right: .25rem!important;
            padding-left: .25rem!important
        }
        .px-lg-2 {
            padding-right: .5rem!important;
            padding-left: .5rem!important
        }
        .px-lg-3 {
            padding-right: 1rem!important;
            padding-left: 1rem!important
        }
        .px-lg-4 {
            padding-right: 1.5rem!important;
            padding-left: 1.5rem!important
        }
        .px-lg-5 {
            padding-right: 3rem!important;
            padding-left: 3rem!important
        }
        .py-lg-0 {
            padding-top: 0!important;
            padding-bottom: 0!important
        }
        .py-lg-1 {
            padding-top: .25rem!important;
            padding-bottom: .25rem!important
        }
        .py-lg-2 {
            padding-top: .5rem!important;
            padding-bottom: .5rem!important
        }
        .py-lg-3 {
            padding-top: 1rem!important;
            padding-bottom: 1rem!important
        }
        .py-lg-4 {
            padding-top: 1.5rem!important;
            padding-bottom: 1.5rem!important
        }
        .py-lg-5 {
            padding-top: 3rem!important;
            padding-bottom: 3rem!important
        }
        .pt-lg-0 {
            padding-top: 0!important
        }
        .pt-lg-1 {
            padding-top: .25rem!important
        }
        .pt-lg-2 {
            padding-top: .5rem!important
        }
        .pt-lg-3 {
            padding-top: 1rem!important
        }
        .pt-lg-4 {
            padding-top: 1.5rem!important
        }
        .pt-lg-5 {
            padding-top: 3rem!important
        }
        .pe-lg-0 {
            padding-right: 0!important
        }
        .pe-lg-1 {
            padding-right: .25rem!important
        }
        .pe-lg-2 {
            padding-right: .5rem!important
        }
        .pe-lg-3 {
            padding-right: 1rem!important
        }
        .pe-lg-4 {
            padding-right: 1.5rem!important
        }
        .pe-lg-5 {
            padding-right: 3rem!important
        }
        .pb-lg-0 {
            padding-bottom: 0!important
        }
        .pb-lg-1 {
            padding-bottom: .25rem!important
        }
        .pb-lg-2 {
            padding-bottom: .5rem!important
        }
        .pb-lg-3 {
            padding-bottom: 1rem!important
        }
        .pb-lg-4 {
            padding-bottom: 1.5rem!important
        }
        .pb-lg-5 {
            padding-bottom: 3rem!important
        }
        .ps-lg-0 {
            padding-left: 0!important
        }
        .ps-lg-1 {
            padding-left: .25rem!important
        }
        .ps-lg-2 {
            padding-left: .5rem!important
        }
        .ps-lg-3 {
            padding-left: 1rem!important
        }
        .ps-lg-4 {
            padding-left: 1.5rem!important
        }
        .ps-lg-5 {
            padding-left: 3rem!important
        }
        .text-lg-start {
            text-align: left!important
        }
        .text-lg-end {
            text-align: right!important
        }
        .text-lg-center {
            text-align: center!important
        }
    }
    @media (min-width:1200px) {
        .float-xl-start {
            float: left!important
        }
        .float-xl-end {
            float: right!important
        }
        .float-xl-none {
            float: none!important
        }
        .d-xl-inline {
            display: inline!important
        }
        .d-xl-inline-block {
            display: inline-block!important
        }
        .d-xl-block {
            display: block!important
        }
        .d-xl-grid {
            display: grid!important
        }
        .d-xl-table {
            display: table!important
        }
        .d-xl-table-row {
            display: table-row!important
        }
        .d-xl-table-cell {
            display: table-cell!important
        }
        .d-xl-flex {
            display: flex!important
        }
        .d-xl-inline-flex {
            display: inline-flex!important
        }
        .d-xl-none {
            display: none!important
        }
        .flex-xl-fill {
            flex: 1 1 auto!important
        }
        .flex-xl-row {
            flex-direction: row!important
        }
        .flex-xl-column {
            flex-direction: column!important
        }
        .flex-xl-row-reverse {
            flex-direction: row-reverse!important
        }
        .flex-xl-column-reverse {
            flex-direction: column-reverse!important
        }
        .flex-xl-grow-0 {
            flex-grow: 0!important
        }
        .flex-xl-grow-1 {
            flex-grow: 1!important
        }
        .flex-xl-shrink-0 {
            flex-shrink: 0!important
        }
        .flex-xl-shrink-1 {
            flex-shrink: 1!important
        }
        .flex-xl-wrap {
            flex-wrap: wrap!important
        }
        .flex-xl-nowrap {
            flex-wrap: nowrap!important
        }
        .flex-xl-wrap-reverse {
            flex-wrap: wrap-reverse!important
        }
        .gap-xl-0 {
            gap: 0!important
        }
        .gap-xl-1 {
            gap: .25rem!important
        }
        .gap-xl-2 {
            gap: .5rem!important
        }
        .gap-xl-3 {
            gap: 1rem!important
        }
        .gap-xl-4 {
            gap: 1.5rem!important
        }
        .gap-xl-5 {
            gap: 3rem!important
        }
        .justify-content-xl-start {
            justify-content: flex-start!important
        }
        .justify-content-xl-end {
            justify-content: flex-end!important
        }
        .justify-content-xl-center {
            justify-content: center!important
        }
        .justify-content-xl-between {
            justify-content: space-between!important
        }
        .justify-content-xl-around {
            justify-content: space-around!important
        }
        .justify-content-xl-evenly {
            justify-content: space-evenly!important
        }
        .align-items-xl-start {
            align-items: flex-start!important
        }
        .align-items-xl-end {
            align-items: flex-end!important
        }
        .align-items-xl-center {
            align-items: center!important
        }
        .align-items-xl-baseline {
            align-items: baseline!important
        }
        .align-items-xl-stretch {
            align-items: stretch!important
        }
        .align-content-xl-start {
            align-content: flex-start!important
        }
        .align-content-xl-end {
            align-content: flex-end!important
        }
        .align-content-xl-center {
            align-content: center!important
        }
        .align-content-xl-between {
            align-content: space-between!important
        }
        .align-content-xl-around {
            align-content: space-around!important
        }
        .align-content-xl-stretch {
            align-content: stretch!important
        }
        .align-self-xl-auto {
            align-self: auto!important
        }
        .align-self-xl-start {
            align-self: flex-start!important
        }
        .align-self-xl-end {
            align-self: flex-end!important
        }
        .align-self-xl-center {
            align-self: center!important
        }
        .align-self-xl-baseline {
            align-self: baseline!important
        }
        .align-self-xl-stretch {
            align-self: stretch!important
        }
        .order-xl-first {
            order: -1!important
        }
        .order-xl-0 {
            order: 0!important
        }
        .order-xl-1 {
            order: 1!important
        }
        .order-xl-2 {
            order: 2!important
        }
        .order-xl-3 {
            order: 3!important
        }
        .order-xl-4 {
            order: 4!important
        }
        .order-xl-5 {
            order: 5!important
        }
        .order-xl-last {
            order: 6!important
        }
        .m-xl-0 {
            margin: 0!important
        }
        .m-xl-1 {
            margin: .25rem!important
        }
        .m-xl-2 {
            margin: .5rem!important
        }
        .m-xl-3 {
            margin: 1rem!important
        }
        .m-xl-4 {
            margin: 1.5rem!important
        }
        .m-xl-5 {
            margin: 3rem!important
        }
        .m-xl-auto {
            margin: auto!important
        }
        .mx-xl-0 {
            margin-right: 0!important;
            margin-left: 0!important
        }
        .mx-xl-1 {
            margin-right: .25rem!important;
            margin-left: .25rem!important
        }
        .mx-xl-2 {
            margin-right: .5rem!important;
            margin-left: .5rem!important
        }
        .mx-xl-3 {
            margin-right: 1rem!important;
            margin-left: 1rem!important
        }
        .mx-xl-4 {
            margin-right: 1.5rem!important;
            margin-left: 1.5rem!important
        }
        .mx-xl-5 {
            margin-right: 3rem!important;
            margin-left: 3rem!important
        }
        .mx-xl-auto {
            margin-right: auto!important;
            margin-left: auto!important
        }
        .my-xl-0 {
            margin-top: 0!important;
            margin-bottom: 0!important
        }
        .my-xl-1 {
            margin-top: .25rem!important;
            margin-bottom: .25rem!important
        }
        .my-xl-2 {
            margin-top: .5rem!important;
            margin-bottom: .5rem!important
        }
        .my-xl-3 {
            margin-top: 1rem!important;
            margin-bottom: 1rem!important
        }
        .my-xl-4 {
            margin-top: 1.5rem!important;
            margin-bottom: 1.5rem!important
        }
        .my-xl-5 {
            margin-top: 3rem!important;
            margin-bottom: 3rem!important
        }
        .my-xl-auto {
            margin-top: auto!important;
            margin-bottom: auto!important
        }
        .mt-xl-0 {
            margin-top: 0!important
        }
        .mt-xl-1 {
            margin-top: .25rem!important
        }
        .mt-xl-2 {
            margin-top: .5rem!important
        }
        .mt-xl-3 {
            margin-top: 1rem!important
        }
        .mt-xl-4 {
            margin-top: 1.5rem!important
        }
        .mt-xl-5 {
            margin-top: 3rem!important
        }
        .mt-xl-auto {
            margin-top: auto!important
        }
        .me-xl-0 {
            margin-right: 0!important
        }
        .me-xl-1 {
            margin-right: .25rem!important
        }
        .me-xl-2 {
            margin-right: .5rem!important
        }
        .me-xl-3 {
            margin-right: 1rem!important
        }
        .me-xl-4 {
            margin-right: 1.5rem!important
        }
        .me-xl-5 {
            margin-right: 3rem!important
        }
        .me-xl-auto {
            margin-right: auto!important
        }
        .mb-xl-0 {
            margin-bottom: 0!important
        }
        .mb-xl-1 {
            margin-bottom: .25rem!important
        }
        .mb-xl-2 {
            margin-bottom: .5rem!important
        }
        .mb-xl-3 {
            margin-bottom: 1rem!important
        }
        .mb-xl-4 {
            margin-bottom: 1.5rem!important
        }
        .mb-xl-5 {
            margin-bottom: 3rem!important
        }
        .mb-xl-auto {
            margin-bottom: auto!important
        }
        .ms-xl-0 {
            margin-left: 0!important
        }
        .ms-xl-1 {
            margin-left: .25rem!important
        }
        .ms-xl-2 {
            margin-left: .5rem!important
        }
        .ms-xl-3 {
            margin-left: 1rem!important
        }
        .ms-xl-4 {
            margin-left: 1.5rem!important
        }
        .ms-xl-5 {
            margin-left: 3rem!important
        }
        .ms-xl-auto {
            margin-left: auto!important
        }
        .p-xl-0 {
            padding: 0!important
        }
        .p-xl-1 {
            padding: .25rem!important
        }
        .p-xl-2 {
            padding: .5rem!important
        }
        .p-xl-3 {
            padding: 1rem!important
        }
        .p-xl-4 {
            padding: 1.5rem!important
        }
        .p-xl-5 {
            padding: 3rem!important
        }
        .px-xl-0 {
            padding-right: 0!important;
            padding-left: 0!important
        }
        .px-xl-1 {
            padding-right: .25rem!important;
            padding-left: .25rem!important
        }
        .px-xl-2 {
            padding-right: .5rem!important;
            padding-left: .5rem!important
        }
        .px-xl-3 {
            padding-right: 1rem!important;
            padding-left: 1rem!important
        }
        .px-xl-4 {
            padding-right: 1.5rem!important;
            padding-left: 1.5rem!important
        }
        .px-xl-5 {
            padding-right: 3rem!important;
            padding-left: 3rem!important
        }
        .py-xl-0 {
            padding-top: 0!important;
            padding-bottom: 0!important
        }
        .py-xl-1 {
            padding-top: .25rem!important;
            padding-bottom: .25rem!important
        }
        .py-xl-2 {
            padding-top: .5rem!important;
            padding-bottom: .5rem!important
        }
        .py-xl-3 {
            padding-top: 1rem!important;
            padding-bottom: 1rem!important
        }
        .py-xl-4 {
            padding-top: 1.5rem!important;
            padding-bottom: 1.5rem!important
        }
        .py-xl-5 {
            padding-top: 3rem!important;
            padding-bottom: 3rem!important
        }
        .pt-xl-0 {
            padding-top: 0!important
        }
        .pt-xl-1 {
            padding-top: .25rem!important
        }
        .pt-xl-2 {
            padding-top: .5rem!important
        }
        .pt-xl-3 {
            padding-top: 1rem!important
        }
        .pt-xl-4 {
            padding-top: 1.5rem!important
        }
        .pt-xl-5 {
            padding-top: 3rem!important
        }
        .pe-xl-0 {
            padding-right: 0!important
        }
        .pe-xl-1 {
            padding-right: .25rem!important
        }
        .pe-xl-2 {
            padding-right: .5rem!important
        }
        .pe-xl-3 {
            padding-right: 1rem!important
        }
        .pe-xl-4 {
            padding-right: 1.5rem!important
        }
        .pe-xl-5 {
            padding-right: 3rem!important
        }
        .pb-xl-0 {
            padding-bottom: 0!important
        }
        .pb-xl-1 {
            padding-bottom: .25rem!important
        }
        .pb-xl-2 {
            padding-bottom: .5rem!important
        }
        .pb-xl-3 {
            padding-bottom: 1rem!important
        }
        .pb-xl-4 {
            padding-bottom: 1.5rem!important
        }
        .pb-xl-5 {
            padding-bottom: 3rem!important
        }
        .ps-xl-0 {
            padding-left: 0!important
        }
        .ps-xl-1 {
            padding-left: .25rem!important
        }
        .ps-xl-2 {
            padding-left: .5rem!important
        }
        .ps-xl-3 {
            padding-left: 1rem!important
        }
        .ps-xl-4 {
            padding-left: 1.5rem!important
        }
        .ps-xl-5 {
            padding-left: 3rem!important
        }
        .text-xl-start {
            text-align: left!important
        }
        .text-xl-end {
            text-align: right!important
        }
        .text-xl-center {
            text-align: center!important
        }
    }
    @media (min-width:1400px) {
        .float-xxl-start {
            float: left!important
        }
        .float-xxl-end {
            float: right!important
        }
        .float-xxl-none {
            float: none!important
        }
        .d-xxl-inline {
            display: inline!important
        }
        .d-xxl-inline-block {
            display: inline-block!important
        }
        .d-xxl-block {
            display: block!important
        }
        .d-xxl-grid {
            display: grid!important
        }
        .d-xxl-table {
            display: table!important
        }
        .d-xxl-table-row {
            display: table-row!important
        }
        .d-xxl-table-cell {
            display: table-cell!important
        }
        .d-xxl-flex {
            display: flex!important
        }
        .d-xxl-inline-flex {
            display: inline-flex!important
        }
        .d-xxl-none {
            display: none!important
        }
        .flex-xxl-fill {
            flex: 1 1 auto!important
        }
        .flex-xxl-row {
            flex-direction: row!important
        }
        .flex-xxl-column {
            flex-direction: column!important
        }
        .flex-xxl-row-reverse {
            flex-direction: row-reverse!important
        }
        .flex-xxl-column-reverse {
            flex-direction: column-reverse!important
        }
        .flex-xxl-grow-0 {
            flex-grow: 0!important
        }
        .flex-xxl-grow-1 {
            flex-grow: 1!important
        }
        .flex-xxl-shrink-0 {
            flex-shrink: 0!important
        }
        .flex-xxl-shrink-1 {
            flex-shrink: 1!important
        }
        .flex-xxl-wrap {
            flex-wrap: wrap!important
        }
        .flex-xxl-nowrap {
            flex-wrap: nowrap!important
        }
        .flex-xxl-wrap-reverse {
            flex-wrap: wrap-reverse!important
        }
        .gap-xxl-0 {
            gap: 0!important
        }
        .gap-xxl-1 {
            gap: .25rem!important
        }
        .gap-xxl-2 {
            gap: .5rem!important
        }
        .gap-xxl-3 {
            gap: 1rem!important
        }
        .gap-xxl-4 {
            gap: 1.5rem!important
        }
        .gap-xxl-5 {
            gap: 3rem!important
        }
        .justify-content-xxl-start {
            justify-content: flex-start!important
        }
        .justify-content-xxl-end {
            justify-content: flex-end!important
        }
        .justify-content-xxl-center {
            justify-content: center!important
        }
        .justify-content-xxl-between {
            justify-content: space-between!important
        }
        .justify-content-xxl-around {
            justify-content: space-around!important
        }
        .justify-content-xxl-evenly {
            justify-content: space-evenly!important
        }
        .align-items-xxl-start {
            align-items: flex-start!important
        }
        .align-items-xxl-end {
            align-items: flex-end!important
        }
        .align-items-xxl-center {
            align-items: center!important
        }
        .align-items-xxl-baseline {
            align-items: baseline!important
        }
        .align-items-xxl-stretch {
            align-items: stretch!important
        }
        .align-content-xxl-start {
            align-content: flex-start!important
        }
        .align-content-xxl-end {
            align-content: flex-end!important
        }
        .align-content-xxl-center {
            align-content: center!important
        }
        .align-content-xxl-between {
            align-content: space-between!important
        }
        .align-content-xxl-around {
            align-content: space-around!important
        }
        .align-content-xxl-stretch {
            align-content: stretch!important
        }
        .align-self-xxl-auto {
            align-self: auto!important
        }
        .align-self-xxl-start {
            align-self: flex-start!important
        }
        .align-self-xxl-end {
            align-self: flex-end!important
        }
        .align-self-xxl-center {
            align-self: center!important
        }
        .align-self-xxl-baseline {
            align-self: baseline!important
        }
        .align-self-xxl-stretch {
            align-self: stretch!important
        }
        .order-xxl-first {
            order: -1!important
        }
        .order-xxl-0 {
            order: 0!important
        }
        .order-xxl-1 {
            order: 1!important
        }
        .order-xxl-2 {
            order: 2!important
        }
        .order-xxl-3 {
            order: 3!important
        }
        .order-xxl-4 {
            order: 4!important
        }
        .order-xxl-5 {
            order: 5!important
        }
        .order-xxl-last {
            order: 6!important
        }
        .m-xxl-0 {
            margin: 0!important
        }
        .m-xxl-1 {
            margin: .25rem!important
        }
        .m-xxl-2 {
            margin: .5rem!important
        }
        .m-xxl-3 {
            margin: 1rem!important
        }
        .m-xxl-4 {
            margin: 1.5rem!important
        }
        .m-xxl-5 {
            margin: 3rem!important
        }
        .m-xxl-auto {
            margin: auto!important
        }
        .mx-xxl-0 {
            margin-right: 0!important;
            margin-left: 0!important
        }
        .mx-xxl-1 {
            margin-right: .25rem!important;
            margin-left: .25rem!important
        }
        .mx-xxl-2 {
            margin-right: .5rem!important;
            margin-left: .5rem!important
        }
        .mx-xxl-3 {
            margin-right: 1rem!important;
            margin-left: 1rem!important
        }
        .mx-xxl-4 {
            margin-right: 1.5rem!important;
            margin-left: 1.5rem!important
        }
        .mx-xxl-5 {
            margin-right: 3rem!important;
            margin-left: 3rem!important
        }
        .mx-xxl-auto {
            margin-right: auto!important;
            margin-left: auto!important
        }
        .my-xxl-0 {
            margin-top: 0!important;
            margin-bottom: 0!important
        }
        .my-xxl-1 {
            margin-top: .25rem!important;
            margin-bottom: .25rem!important
        }
        .my-xxl-2 {
            margin-top: .5rem!important;
            margin-bottom: .5rem!important
        }
        .my-xxl-3 {
            margin-top: 1rem!important;
            margin-bottom: 1rem!important
        }
        .my-xxl-4 {
            margin-top: 1.5rem!important;
            margin-bottom: 1.5rem!important
        }
        .my-xxl-5 {
            margin-top: 3rem!important;
            margin-bottom: 3rem!important
        }
        .my-xxl-auto {
            margin-top: auto!important;
            margin-bottom: auto!important
        }
        .mt-xxl-0 {
            margin-top: 0!important
        }
        .mt-xxl-1 {
            margin-top: .25rem!important
        }
        .mt-xxl-2 {
            margin-top: .5rem!important
        }
        .mt-xxl-3 {
            margin-top: 1rem!important
        }
        .mt-xxl-4 {
            margin-top: 1.5rem!important
        }
        .mt-xxl-5 {
            margin-top: 3rem!important
        }
        .mt-xxl-auto {
            margin-top: auto!important
        }
        .me-xxl-0 {
            margin-right: 0!important
        }
        .me-xxl-1 {
            margin-right: .25rem!important
        }
        .me-xxl-2 {
            margin-right: .5rem!important
        }
        .me-xxl-3 {
            margin-right: 1rem!important
        }
        .me-xxl-4 {
            margin-right: 1.5rem!important
        }
        .me-xxl-5 {
            margin-right: 3rem!important
        }
        .me-xxl-auto {
            margin-right: auto!important
        }
        .mb-xxl-0 {
            margin-bottom: 0!important
        }
        .mb-xxl-1 {
            margin-bottom: .25rem!important
        }
        .mb-xxl-2 {
            margin-bottom: .5rem!important
        }
        .mb-xxl-3 {
            margin-bottom: 1rem!important
        }
        .mb-xxl-4 {
            margin-bottom: 1.5rem!important
        }
        .mb-xxl-5 {
            margin-bottom: 3rem!important
        }
        .mb-xxl-auto {
            margin-bottom: auto!important
        }
        .ms-xxl-0 {
            margin-left: 0!important
        }
        .ms-xxl-1 {
            margin-left: .25rem!important
        }
        .ms-xxl-2 {
            margin-left: .5rem!important
        }
        .ms-xxl-3 {
            margin-left: 1rem!important
        }
        .ms-xxl-4 {
            margin-left: 1.5rem!important
        }
        .ms-xxl-5 {
            margin-left: 3rem!important
        }
        .ms-xxl-auto {
            margin-left: auto!important
        }
        .p-xxl-0 {
            padding: 0!important
        }
        .p-xxl-1 {
            padding: .25rem!important
        }
        .p-xxl-2 {
            padding: .5rem!important
        }
        .p-xxl-3 {
            padding: 1rem!important
        }
        .p-xxl-4 {
            padding: 1.5rem!important
        }
        .p-xxl-5 {
            padding: 3rem!important
        }
        .px-xxl-0 {
            padding-right: 0!important;
            padding-left: 0!important
        }
        .px-xxl-1 {
            padding-right: .25rem!important;
            padding-left: .25rem!important
        }
        .px-xxl-2 {
            padding-right: .5rem!important;
            padding-left: .5rem!important
        }
        .px-xxl-3 {
            padding-right: 1rem!important;
            padding-left: 1rem!important
        }
        .px-xxl-4 {
            padding-right: 1.5rem!important;
            padding-left: 1.5rem!important
        }
        .px-xxl-5 {
            padding-right: 3rem!important;
            padding-left: 3rem!important
        }
        .py-xxl-0 {
            padding-top: 0!important;
            padding-bottom: 0!important
        }
        .py-xxl-1 {
            padding-top: .25rem!important;
            padding-bottom: .25rem!important
        }
        .py-xxl-2 {
            padding-top: .5rem!important;
            padding-bottom: .5rem!important
        }
        .py-xxl-3 {
            padding-top: 1rem!important;
            padding-bottom: 1rem!important
        }
        .py-xxl-4 {
            padding-top: 1.5rem!important;
            padding-bottom: 1.5rem!important
        }
        .py-xxl-5 {
            padding-top: 3rem!important;
            padding-bottom: 3rem!important
        }
        .pt-xxl-0 {
            padding-top: 0!important
        }
        .pt-xxl-1 {
            padding-top: .25rem!important
        }
        .pt-xxl-2 {
            padding-top: .5rem!important
        }
        .pt-xxl-3 {
            padding-top: 1rem!important
        }
        .pt-xxl-4 {
            padding-top: 1.5rem!important
        }
        .pt-xxl-5 {
            padding-top: 3rem!important
        }
        .pe-xxl-0 {
            padding-right: 0!important
        }
        .pe-xxl-1 {
            padding-right: .25rem!important
        }
        .pe-xxl-2 {
            padding-right: .5rem!important
        }
        .pe-xxl-3 {
            padding-right: 1rem!important
        }
        .pe-xxl-4 {
            padding-right: 1.5rem!important
        }
        .pe-xxl-5 {
            padding-right: 3rem!important
        }
        .pb-xxl-0 {
            padding-bottom: 0!important
        }
        .pb-xxl-1 {
            padding-bottom: .25rem!important
        }
        .pb-xxl-2 {
            padding-bottom: .5rem!important
        }
        .pb-xxl-3 {
            padding-bottom: 1rem!important
        }
        .pb-xxl-4 {
            padding-bottom: 1.5rem!important
        }
        .pb-xxl-5 {
            padding-bottom: 3rem!important
        }
        .ps-xxl-0 {
            padding-left: 0!important
        }
        .ps-xxl-1 {
            padding-left: .25rem!important
        }
        .ps-xxl-2 {
            padding-left: .5rem!important
        }
        .ps-xxl-3 {
            padding-left: 1rem!important
        }
        .ps-xxl-4 {
            padding-left: 1.5rem!important
        }
        .ps-xxl-5 {
            padding-left: 3rem!important
        }
        .text-xxl-start {
            text-align: left!important
        }
        .text-xxl-end {
            text-align: right!important
        }
        .text-xxl-center {
            text-align: center!important
        }
    }
    @media (min-width:1200px) {
        .fs-1 {
            font-size: 2.5rem!important
        }
        .fs-2 {
            font-size: 2rem!important
        }
        .fs-3 {
            font-size: 1.75rem!important
        }
        .fs-4 {
            font-size: 1.5rem!important
        }
    }
    @media print {
        .d-print-inline {
            display: inline!important
        }
        .d-print-inline-block {
            display: inline-block!important
        }
        .d-print-block {
            display: block!important
        }
        .d-print-grid {
            display: grid!important
        }
        .d-print-table {
            display: table!important
        }
        .d-print-table-row {
            display: table-row!important
        }
        .d-print-table-cell {
            display: table-cell!important
        }
        .d-print-flex {
            display: flex!important
        }
        .d-print-inline-flex {
            display: inline-flex!important
        }
        .d-print-none {
            display: none!important
        }
    }
    /*# sourceMappingURL=bootstrap.min.css.map */
}

.breadcrumb {
    background-color: transparent;
    padding-left: 0 !important;
    color: #fff;
    a {
        color: #fff;
    }
}

.breadcrumb-item+.breadcrumb-item {
    color: #fff !important;
    &::before {
        color: #fff;
    }
}

.propiedad {
    .data {
        background-color: #2B2B2B;
        padding-top: 10vh;
        overflow: hidden;
        max-width: 100vw;
        h2 {
            font-size: 4rem;
            margin-bottom: 0;
            line-height: 1;
            color: #fff;
            letter-spacing: 4px;
            font-weight: 200;
            &.sub_title {
                font-weight: 200;
                color: #fff;
                margin-top: 10vh;
            }
        }
        .btn-square {
            padding: .4rem 1.5rem;
            border: 2px solid #A58D28;
            color: #fff;
            font-size: 1rem;
            margin-right: .7rem;
            // opacity: ;
            transition: .3s ease all;
            &:hover {
                color: #A58D28;
                background: #fff;
                i {
                    color: #A58D28;
                }
            }
            i {
                margin-right: 1rem;
            }
            &.small {
                width: 3rem;
                height: 3rem;
                padding: .4rem;
                color: #fff;
                .icon-solid-like {
                    color: transparent;
                    -webkit-text-stroke: 2px #fff;
                }
                i {
                    margin-right: 0rem;
                }
                &:hover,
                &.active {
                    background: #A58D28;
                    i {
                        color: #0F0F0F;
                        -webkit-text-stroke: none !important;
                    }
                    .icon-solid-like {
                        -webkit-text-stroke: 0px !important;
                    }
                }
            }
        }
        .tag {
            padding: .5rem 1.3rem;
            font-size: .8rem;
            font-weight: 300;
            color: #fff;
            &.primary-tag,
            &.Alquiler {
                background: transparent;
            }
        }
        .item {
            color: #fff;
            .tick {
                background: #A58D28;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 100%;
                i {
                    font-size: 1rem;
                }
            }
        }
        .item-extra {
            margin-top: .5rem;
            font-size: 1.3rem;
            color: #C3C3C3;
            align-items: center;
            i {
                color: #797979;
                text-align: center;
                font-size: 4rem;
                &.icon-gym {
                    font-size: .7rem;
                }
                &.icon-home {
                    font-size: 1.1rem;
                    margin-right: 1.8rem !important;
                    display: block;
                }
            }
        }
        hr {
            height: 1rem;
            background: #A58D28;
            opacity: 1;
            margin: 7vh 0;
        }
        .content-description {
            padding: 7vh 10vh;
            padding-right: 0;
            height: 100%;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                right: -25%;
                background-color: inherit;
                width: 100%;
                top: 0;
                z-index: 0;
                height: 100%;
            }
        }
        p {
            font-size: 1.4rem;
            color: #EBEBEB;
            position: relative;
            // margin-top: 3rem;
            line-height: 1.3;
            width: 100%;
            font-weight: 200;
        }
        .content-price {
            display: block;
            h3 {
                font-size: 3rem;
                font-weight: 600;
                color: #EBEBEB;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                text-align: start;
                // &::before {
                //     content: '';
                //     width: 2rem;
                //     height: 2rem;
                //     margin-right: 2.5rem;
                //     display: inline-block;
                //     position: absolute;
                //     left: 0;
                // }
                // &.Alquiler {
                //     &::before {
                //         background: #A58D28;
                //     }
                // }
                // &.Venta {
                //     &::before {
                //         background: #fff;
                //     }
                // }
                &.min-size {
                    padding-left: 0;
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
        .global_view {
            height: 60vh;
            width: 100%;
            margin-top: 3rem;
        }
        .contacts {
            p {
                font-size: 1em;
                line-height: 2;
                margin-top: 2em;
                color: #fff;
                opacity: .5;
                margin: 0;
                text-align: start;
            }
            .form-both {
                display: flex;
            }
        }
    }
}

@media (max-width:992px) {
    .propiedad {
        .data {
            padding: 5vh 1rem 0 1rem;
            padding-top: 5vh;
            h2 {
                font-size: 2.6rem;
                &.sub_title {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }
                .location {
                    font-size: 1.3rem;
                    margin: .5rem 0 2rem 0;
                }
            }
            .btn {
                padding: .0rem 1rem;
                font-size: 1rem;
                margin-right: .5rem;
            }
            .tag {
                padding: .3rem 1rem;
                margin-bottom: 4vh;
                margin-top: 1rem;
                font-size: .7rem;
            }
            .item {
                .tick {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 100%;
                }
            }
            .item-extra {
                font-size: .9rem;
                // overflow-x: scroll;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                &:nth-child(4) {
                    margin-bottom: 2vh;
                }
                i {
                    // width: 2rem;
                    &.icon-bathtub {
                        font-size: 1.2rem;
                    }
                }
            }
            p {
                font-size: 1.1rem;
                width: 100%;
            }
            .content-description {
                background-color: transparent !important;
                padding: 3vh 0vh;
                p {
                    display: none;
                }
                .content-price {
                    margin: 4vh 0 0 0;
                    margin-left: 0;
                    padding-left: 0;
                    h3 {
                        font-size: 2.5rem;
                        min-width: unset;
                        padding-left: 0;
                    }
                }
            }
            .contact-main {
                form {
                    padding-top: 0vh !important;
                }
                h2 {
                    font-size: 2rem !important;
                }
                .btn {
                    font-size: .9rem;
                    padding: 0 2rem;
                    padding-left: calc(2rem + 3rem);
                    width: 100%;
                }
            }
            .form-both .btn {
                width: 100% !important;
            }
        }
        .project figure {
            height: 40vh;
        }
    }
}

.propiedad {
    .main {
        background-position: center;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        position: relative;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            content: '';
            // background-color: #0f0f0f;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.751278) 10%, rgba(115, 115, 115, 0) 50%, rgba(0, 0, 0, 0.351278) 100%);
            // background-attachment: fixed;
            background-position: center;
            background-size: cover;
            opacity: 1;
            z-index: 9;
            display: block;
        }
        &::after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            z-index: 2;
            height: 1.1rem;
            opacity: 0;
            display: block;
            background-image: #A58D28;
        }
        .content-main {
            height: 100vh;
            color: #fff;
            padding-bottom: 4rem;
            z-index: 10;
            position: relative;
            .btn-square {
                padding: 0;
                width: 5rem;
                color: #fff;
                border-radius: 50%;
                background: transparent;
                height: 5rem;
                line-height: 1;
                opacity: .7 !important;
                transition: .3s ease all;
                color: transparent;
                position: relative;
                transition: 1s ease-in-out all;
                cursor: pointer;
                &::before {
                    content: '+';
                    text-align: center;
                    position: absolute;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    top: 50%;
                    left: 50%;
                    line-height: 0;
                    margin-bottom: .25rem;
                    padding-bottom: 5px;
                    transform: translate(-50%, -50%);
                    color: #fff;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    font-size: 2.5rem;
                    transition: .2s ease-in-out all !important;
                    width: 2.5rem;
                    height: 2.5rem;
                }
                i {
                    color: #fff;
                    margin: 0;
                    transition: .3s ease all;
                }
                &:hover {
                    &::before {
                        // width: 2.8rem !important;
                        // height: 2.8rem !important;
                        width: 3rem;
                        height: 3rem;
                    }
                }
            }
            h2 {
                font-size: 3rem;
            }
            .btn {
                padding: 0;
                width: 5.5rem;
                height: 5.5rem;
                font-size: 1.5rem;
                img {
                    width: 100%;
                    opacity: 0;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .propiedad {
        .main {
            height: 90vh;
            background-attachment: unset;
            position: relative;
            min-height: 90vh;
            &::before {
                opacity: .6;
            }
            &::after {
                height: 90vh;
                background-color: #1f1f1f;
                opacity: .2;
                background-image: none;
            }
            .content-main {
                padding-bottom: 0;
                height: 90vh;
                h2 {
                    font-size: 2.5rem;
                    font-weight: 300;
                    padding-left: 1rem;
                    display: block;
                    margin-bottom: 0 !important;
                    .location {
                        font-size: 1rem;
                    }
                }
                .btn-square {
                    width: 4rem;
                    position: absolute !important;
                    left: 1rem;
                    bottom: 5vh;
                    height: 4rem;
                    font-size: 1rem;
                }
            }
            .btn {
                width: 4rem;
                height: 4rem;
                position: absolute !important;
                left: calc(0%);
                bottom: 5vh;
                font-size: 1rem;
            }
        }
    }
}

.backdrop {
    width: 100% !important;
    height: 100% !important;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0000008f;
    align-items: center;
    .member {
        position: relative;
        h4 {
            position: absolute !important;
            bottom: 0;
            width: 100%;
            text-align: center;
        }
        .close {
            position: absolute;
            right: .5rem;
            top: 2rem;
            width: 3rem;
            height: 3rem;
            padding-bottom: 1rem;
            i {
                width: 1.5rem;
                height: 2px;
                display: block;
                background-color: #fff;
                opacity: 1;
                transform: rotate(45deg);
                &:nth-child(1) {
                    transform: rotate(-45deg) translateX(-1px) translateY(1px);
                }
            }
        }
    }
    @media(max-width:992px) {
        .member {
            img {
                width: 100%;
            }
        }
    }
}

@media (min-width:992px) {
    .container-fluider {
        max-width: 85%;
        margin: auto;
    }
}

.banner-main {
    height: 100vh;
    background-size: cover;
    background-position: center;
    width: 100%;
    video,
    img {
        height: 100vh;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.div_body_novelty {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span,
    a {
        color: #1f1f1f;
        text-align: left;
    }
    p {
        font-size: 1.2rem;
        line-height: 1.3;
        margin-bottom: 0;
        color: #686868;
    }
    img,
    iframe {
        width: 100%;
        object-fit: cover;
        object-position: center;
        height: calc(((100vw / 12) * 8) * 0.4);
        @media(max-width: 992px) {
            height: 30vh;
        }
        /* 9/16 */
    }
    blockquote {
        opacity: .6;
        margin: 3rem 0;
        display: block;
        font-size: 1.3rem;
        padding: 0 2.5rem;
        position: relative;
        border-left: none;
        text-align: center;
        &::before,
        &::after {
            font-size: 7rem;
            line-height: 1rem;
            opacity: .4;
            font-family: 'sans-serif', sans-serif;
        }
        &::after {
            content: '”';
            margin-left: 1rem;
            margin-top: auto;
            position: absolute;
            right: 0;
            bottom: -1rem;
        }
        &::before {
            content: '‟';
            top: 1.5rem;
            margin-right: 1rem;
            left: 0;
            position: absolute;
        }
        @media(max-width: 992px) {
            font-size: 1.2rem;
            &::before,
            &::after {
                font-size: 5rem;
            }
        }
    }
    ul {
        list-style: none;
        padding-left: 1rem;
        text-decoration: none;
        li {
            display: block;
            font-size: 1.2rem;
            line-height: 1.7;
            &::before {
                content: '●';
                color: #1f1f1f;
                display: inline-block;
                margin-right: 6px;
            }
            @media(max-width: 992px) {
                font-size: 1rem;
            }
        }
    }
    line-height: 1.5;
    .div_video {
        .video {
            height: 400px;
            width: 100%;
            background: grey;
        }
    }
    a {
        color: #b01b40 !important;
        text-decoration: underline !important;
        transition: .3s ease-in all;
        &:hover {
            opacity: .7;
        }
    }
}

.div_gallery {
    margin-top: 0;
    &.gallery-space-top {
        margin-top: 3rem;
    }
    .image-gallery-slides {
        text-align: center;
    }
    .image-gallery-slide {
        background: #686868;
    }
    .image-gallery-slide img {
        width: 100%;
        height: 50vh;
    }
    .image-gallery-content.fullscreen .image-gallery-slide img {
        height: 90vh;
    }
    .image-gallery-thumbnail {
        overflow: hidden;
    }
    .image-gallery-thumbnails {
        text-align: center;
    }
    .image-gallery-thumbnail-inner {
        background: #fff;
    }
    .image-gallery-thumbnail-inner img {
        width: auto;
        height: 75px;
        object-fit: contain;
    }
    .image-gallery-icon:hover {
        color: #b01b40;
    }
    .image-gallery-bullets .image-gallery-bullet:hover {
        background: #b01b40;
        border: 1px solid #b01b40;
    }
    .image-gallery-bullets .image-gallery-bullet.active:hover,
    .image-gallery-bullets .image-gallery-bullet.active {
        background: #b01b40;
    }
    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus,
    .image-gallery-thumbnail:hover {
        outline: none;
        border: 4px solid #b01b40;
    }
    .image-gallery-fullscreen-button {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 16px;
        padding: 5px;
    }
    .image-gallery-index {
        position: absolute;
        width: 80px;
        left: 0;
        top: 0;
        text-align: center;
        @media(max-width: 768px) {
            font-size: .8em;
            padding: 5px 10px;
            width: 50px;
        }
        span.image-gallery-index-current, 
        span.image-gallery-index-separator,
        span.image-gallery-index-total {
            color: #fff!important;
        }
    }
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 60px;
        width: 30px;
    }
    .image-gallery-icon {
        filter: drop-shadow(0 1px 1px lighten(#000, 10%));
    }
    .image-gallery-description {
        bottom: 5%;
        right: 0%;
        line-height: 1;
        position: absolute;
        padding: 10px;
        vertical-align: middle;
        color: #fff!important;
    }
    .image-gallery-slide .image-gallery-description {
        bottom: 0px;
        left: 0;
        padding-top: 10px;
        position: absolute;
        vertical-align: middle;
    }
    .image-gallery-bullets {
        bottom: 50px;
        left: 0;
        position: absolute;
    }
}

.div_files {
    margin-top: 0;
    &.files-space-top {
        margin-top: 3rem;
    }
    .div_header_novelty {
        h4.subtitle {
            color: #686868;
            letter-spacing: 0;
            text-transform: none;
            margin: 2rem 0 2rem 0;
            font-family: 'Oswald', sans-serif;
        }
    }
    .with-bt {
        margin-bottom: 2rem; 
        border-top: 1px solid #686868;
    }
    .linkStyled {
        a,
        a:hover {
          text-decoration: none;
          color: #b01b40;
        }
        i.icon-download {
            margin-right: .5rem;
        }
    }
}

.blog-tags {
    margin-top: 0!important;
    &.blog-space-top {
        margin-top: 3rem!important;
    }
    .tag {
        margin-right: 10px;
    }
}