$primary-color: #b01b40;
$secondary-color: #2b2b2b;
$tertiary-color: #606060;
$white: #ffffff;
$black: #000000;
// Hidden button
button#squarePopUpBtn {
    top: 0;
    margin: 0;
    border: 0;
    padding: 0;
    width: 1px;
    z-index: -1;
    height: 1px;
    position: absolute;
}

// Modal styles
div#squarePopUp.modal.fade {
    .modal-dialog {
        display: flex;
        height: 100vh;
        align-items: center;
        &.flat-width {
            max-width: unset;
        }
        .modal-content {
            height: 80vh;
            width: calc(80vh*1.33);
            @media(max-width: 992px) {
                height: 33vh;
                width: calc(33vh*1.33);
            }
            margin: auto;
            border: none;
            background-clip: unset;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            // background-image: url('../images/Baigun-oficinas.jpg');
            .modal-header {
                border: none;
                display: flex;
                justify-content: flex-end;
                i.icon-delete {
                    text-shadow: 0 0 3px rgba(0, 0, 0, .6);
                    color: #fff;
                    font-size: 2rem;
                    z-index: 99;
                    transform: rotate(45deg);
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            // Flat - limit height
            &.flat-width {
                height: 35vh;
                width: calc(35vh * 4);
                margin: auto;
            }
            // Modal body with link
            .modal-body {
                z-index: 10;
                width: 100%;
                height: 75vh;
                bottom: 0;
                position: absolute;
                &.flat-width {
                    height: 39vh;
                }
            }
            &:hover {
                cursor: pointer;
            }
        }
        // Responsive modal
        @media (min-width: 576px) {
            max-width: 60vw;
        }
        // Alignment
        &.align-left,
        &.align-right,
        &.align-center {
            max-width: 89vw;
            margin: auto;
            .modal-body,
            .modal-content {
                width: 47vh;
                height: 85vh;
                @media(max-width: 992px) {
                    width: 100vw;
                    height: calc(100vw * 1.58);
                }
            }
        }
        &.align-left {
            justify-content: flex-start;
            @media (max-width: 991px) {
                margin: 0 auto;
                justify-content: center;
                .modal-content {
                    width: 88%;
                }
            }
        }
        &.align-right {
            justify-content: flex-end;
            @media (max-width: 991px) {
                margin: 0 auto;
                justify-content: center;
                .modal-content {
                    width: 88%;
                }
            }
        }
        &.align-center {
            justify-content: center;
        }
    }
}