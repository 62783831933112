$primary-color: #b01b40;
$secondary-color: #2b2b2b;
$tertiary-color: #606060;
$white: #ffffff;
$black: #000000;
#top-alert.alert {
    top: -57px;
    opacity: 0;
    top: 0px;
    width: 100%;
    color: #fff;
    font-weight: 100;
    margin-bottom: 0;
    height: 4.5rem;
    position: fixed;
    display: flex !important;
    align-items: center;
    text-align: center;
    z-index: 99999;
    pointer-events: none;
    background-color: #696969;
    transition: .5s all ease-in-out;
    @media (max-width: 992px) {
        height: 10rem;
    }
    &.animate-show {
        transition: .5s all ease-in-out;
        top: 0;
        pointer-events: unset;
        opacity: 1;
    }
    .container-fluid {
        display: flex;
        width: 100%;
        margin: auto;
        align-items: center;
        justify-content: center;
        @media(max-width: 992px) {
            display: block;
        }
        .counter-wrapper {
            min-height: 16px;
            align-items: center;
            flex-direction: row;
            display: inline-flex;
            @media (max-width: 826px) {
                flex-direction: column;
                display: flex;
            }
            .counter {
                color: #f9c704;
                @media (max-width: 826px) {
                    margin-top: -0.25rem;
                    margin-bottom: -0.25rem;
                }
                span {
                    font-weight: 100;
                    margin-right: .3rem;
                    span.date-val {
                        font-weight: 400;
                        font-size: 1.2rem;
                        @media (max-width: 991px) {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }
        .text-content {
            display: inline-block;
        }
        .btn-link {
            color: #696969;
            border-radius: 4px;
            margin-left: 0.8rem;
            display: inline-block;
            padding: 0.4rem 0.8rem;
            max-width: fit-content;
            background-color: #f9c704;
            text-decoration: none !important;
            &:hover {
                cursor: pointer;
                background-color: #d5ab05;
                text-decoration: none !important;
            }
            @media (max-width: 961px) {
                display: block;
                margin: 5px auto 0 auto;
            }
        }
    }
    i.icon-delete {
        right: 0;
        z-index: 2;
        top: 0.5rem;
        color: #fff;
        padding: 1rem;
        cursor: pointer;
        font-size: 1.2rem;
        position: absolute;
        // transform: rotate(45deg);
    }
}

// #top-alert.alert.animate-show+header#top {
//     top: 4rem;
//     @media (max-width: 992px) {
//         top: 10rem;
//     }
// }
// #top-alert.alert+header#header {
//     top: 4rem;
//     @media (max-width: 992px) {
//         top: 10rem;
//     }
// }
#top-alert.alert.animate-show+header#top {
    padding-top: 4.5rem;
    @media (max-width: 992px) {
        padding-top: 10rem;
    }
}

#top-alert.alert.animate-show+header#top {
    .brand-panel {
        @media (max-width: 992px) {
            z-index: 4 !important;
        }
    }
    .header-phone {
        padding-top: 4.5rem;
        @media (max-width: 992px) {
            padding-top: 10rem;
        }
    }
    .brand-panel,
    .vertical-panel {
        bottom: -4.5rem;
        @media (max-width: 992px) {
            top: 10rem;
        }
    }
    .navbar-desctop {
        top: 4.5rem;
    }
    .navbar-mobile {
        top: 10rem;
    }
}

#top-alert.alert.animate-show+header {
    transition: .5s ease-in-out all;
    top: 4rem;
    @media (max-width: 992px) {
        top: 10rem;
        transition: .5s ease-in-out all;
    }
    .brand-panel {
        @media (max-width: 992px) {
            z-index: 2 !important;
        }
    }
    &.contact-nav {
        top: 0;
        padding-top: 3rem;
        padding-bottom: 0.5rem;
        background-color: #fff;
        @media (max-width: 992px) {
            top: 10rem;
        }
    }
    &.results-nav {
        transition: .5s ease-in-out all;
        top: 4rem !important;
        @media (max-width: 992px) {
            transition: .5s ease-in-out all;
            top: 10rem !important;
        }
    }
    &.home-nav {
        transition: .5s ease-in-out all;
        top: 4rem;
        @media (max-width: 992px) {
            top: 10rem;
            transition: .5s ease-in-out all;
        }
    }
}

// #top-alert.alert+main {
//     padding-top: 4rem;
//     @media (max-width: 992px) {
//         padding-top: 10rem;
//     }
// }